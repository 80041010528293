import { isEmpty } from "lodash";

import {
  dataTransformationAPI,
  DataTransformationEndpoints,
  IAnalysisEdaMetaData,
  IGetTransformationsResponse,
  IPutSampleResponse,
  IRefreshAPI,
  ISamplingTechniquesResponse,
  IStrapiTransformation,
  ITaskResponse,
  ITerminateWorkflow,
  IUpdateSamplingRequest,
} from "@/features/data-transformation";
import {
  IPutEDAResponse,
  IPutEDARequest,
} from "@/features/data-transformation/types";
import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter.ts";

const mapSamplingTechniques = (
  res: ApiResponse<ISamplingTechniquesResponse>
) => {
  const samplingTechniques = res.response.data!.samplingTechniques;
  const parameters = res.response.data!.parameters;
  const currentSampling = res.response.data!.currentSampling;

  const populatedSamplingTechniques = samplingTechniques.map((technique) => {
    const _parameters = technique.parameters.map((param: unknown) => {
      return parameters.find(
        (parameter) => parameter.id === (param as string)
      )!;
    });
    return {
      ...technique,
      parameters: _parameters,
    };
  });

  let updatedCurrentSampling = currentSampling;
  if (!isEmpty(currentSampling)) {
    const _populatedParams = currentSampling.parameters.map((parameter) => {
      const _param = parameters.find(
        (param) => param.shortName === parameter.shortName
      )!;
      return {
        ...parameter,
        ..._param,
      };
    });
    const _populatedSampling = populatedSamplingTechniques.find(
      (sample) => sample.shortName === currentSampling.shortName
    );

    updatedCurrentSampling = {
      ..._populatedSampling,
      ...currentSampling,
      parameters: _populatedParams,
    };
  }

  return {
    ...res,
    response: {
      data: {
        ...res.response.data,
        parameters: parameters,
        samplingTechniques: populatedSamplingTechniques,
        currentSampling: updatedCurrentSampling,
      },
    },
  };
};

export const edaInitializationAPI = dataTransformationAPI.injectEndpoints({
  endpoints: (build) => ({
    putEDA: build.mutation<ApiResponse<IPutEDAResponse>, IPutEDARequest>({
      query: ({ analysisId = "", ...props }) => ({
        url: DataTransformationEndpoints.edaApi(analysisId),
        method: "PUT",
        data: {
          ...keysToSnake(props),
        },
      }),
    }),
    getSampling: build.query<
      ApiResponse<ISamplingTechniquesResponse>,
      IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId }) => ({
        url: DataTransformationEndpoints.samplingTechniques(analysisId, edaId),
        method: "GET",
      }),
      transformResponse: mapSamplingTechniques,
    }),
    startTransforming: build.mutation<ApiResponse<any>, IAnalysisEdaMetaData>({
      query: ({ analysisId = "", edaId }) => ({
        url: DataTransformationEndpoints.startEditing(analysisId, edaId),
        method: "PUT",
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getRequestStatus: build.query<
      ApiResponse<ITaskResponse>,
      IAnalysisEdaMetaData & { requestId: string }
    >({
      query: ({ analysisId, edaId, requestId }) => ({
        url: DataTransformationEndpoints.getRequestDataStatus(
          analysisId,
          edaId,
          requestId
        ),
        method: "GET",
      }),
      extraOptions: {
        maxRetries: 2,
      },
    }),
    updateSampling: build.mutation<
      ApiResponse<IPutSampleResponse>,
      IAnalysisEdaMetaData & Partial<IUpdateSamplingRequest>
    >({
      query: ({ analysisId = "", edaId, ...props }) => ({
        url: DataTransformationEndpoints.updateSample(analysisId, edaId),
        method: "PUT",
        data: {
          sampling: {
            ...keysToSnake(props),
          },
        },
      }),
    }),
    getTransformations: build.query<
      ApiResponse<IGetTransformationsResponse>,
      unknown
    >({
      query: () => ({
        url: DataTransformationEndpoints.getTransformations,
        method: "GET",
      }),
      // Permanent caching
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
    getTransformationDescriptions: build.query<
      IStrapiTransformation,
      { shortName: string }
    >({
      query: ({ shortName }) => ({
        url: DataTransformationEndpoints.getTransformationsDescriptions,
        method: "GET",
        params: {
          "filters[short_name][$eq]": shortName,
        },
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_KEY}`,
        },
        ignoreBaseUrl: true,
        customAuth: true,
      }),
      // Permanent caching
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
    getReadonlyModeStatus: build.query<
      ApiResponse<IRefreshAPI>,
      IAnalysisEdaMetaData
    >({
      query: ({ analysisId, edaId }) => ({
        url: DataTransformationEndpoints.refresh(analysisId, edaId),
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    terminateExecution: build.mutation<
      ApiResponse<ITerminateWorkflow>,
      IAnalysisEdaMetaData & { requestId: string }
    >({
      query: ({ analysisId, edaId, requestId }) => ({
        url: DataTransformationEndpoints.terminate(
          analysisId,
          edaId,
          requestId
        ),
        method: "PUT",
      }),
    }),
    closeEDA: build.mutation<ApiResponse<any>, IAnalysisEdaMetaData>({
      query: ({ analysisId, edaId }) => ({
        url: DataTransformationEndpoints.closeEda(analysisId, edaId),
        method: "POST",
      }),
    }),
  }),
});

export const {
  usePutEDAMutation,
  useLazyGetSamplingQuery,
  useUpdateSamplingMutation,
  useGetRequestStatusQuery,
  useGetTransformationsQuery,
  useGetTransformationDescriptionsQuery,
  useGetReadonlyModeStatusQuery,
  useLazyGetReadonlyModeStatusQuery,
  useTerminateExecutionMutation,
  useCloseEDAMutation,
  useStartTransformingMutation,
} = edaInitializationAPI;
