type NumericInput = string | number;

export function limitDecimalPoints(
  input: NumericInput,
  decimalPoints: number
): string {
  // Convert input to number
  const num = typeof input === "string" ? parseFloat(input) : input;

  // Ensure we're dealing with a valid number; if not, just return the string
  if (isNaN(num)) {
    return `${input}`;
  }

  // Truncate to specified decimal points
  const factor = Math.pow(10, decimalPoints);
  const truncatedNumber = Math.trunc(num * factor) / factor;

  // Format the number to ensure it shows all specified decimal points
  const formattedNumber = truncatedNumber.toFixed(decimalPoints);

  // Remove trailing zeros after the decimal point, but keep one if it's a whole number
  const cleanedNumber =
    formattedNumber.replace(/\.?0+$/, "") || formattedNumber;

  // Return as string
  return cleanedNumber;
}

export const getBooleanEnv = (key: string, defaultValue = false) => {
  const value = import.meta.env[key];
  if (value === undefined) return defaultValue;
  return value.toLowerCase() === "true";
};
