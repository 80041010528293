export const DataTransformationEndpoints = {
  edaApi: (analysisId: string) => `/api/v1.0/analysis/${analysisId}/eda/`,
  getTableData: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/table/data`,
  samplingTechniques: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/sample/techniques`,
  updateSample: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/sample`,
  getRequestDataStatus: (analysisId: string, eda: string, requestId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${eda}/requests/${requestId}/status/`,
  getTransformations: "/api/v1.0/eda/transformations/",
  getTransformationsDescriptions:
    import.meta.env.VITE_STRAPI_BASE_URL + "/transformation-descriptions",
  getSteps: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/steps`,
  saveSteps: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/steps/save/`,
  addNewStep: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/steps/`,
  editStep: (analysisId: string, edaId: string, stepId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/steps/${stepId}/`,
  filterStats: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/filter/stats/`,
  exploreDataStats: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/explore/stats/`,
  refresh: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/refresh/`,
  terminate: (analysisId: string, edaId: string, requestId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/request/${requestId}/cancel/`,
  getSuggestions: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/suggestions/`,
  closeEda: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/back/`,
  startEditing: (analysisId: string, edaId: string) =>
    `/api/v1.0/analysis/${analysisId}/eda/${edaId}/transform/data`,
};
