import { Box, chakra, Flex, Spinner, Tag, Text } from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  MdsArrowDownwardRound,
  MdsArrowUpwardRound,
  MdsSwapVertRound,
} from "react-icons-with-materialsymbols/mds";

import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@/design/components/data-table";
import { StyledIcon } from "@/features/ws-manager/components/styled-icon.tsx";
import {
  AnalysesSchema,
  CommonItemsSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import { useAppDispatch } from "@/reduxHooks.ts";
import { showContextMenu } from "@/slices/context-menu-slice.ts";
import { getDateFormat } from "@/utils/date-convertor.ts";
import { CATEGORY } from "@/utils/enums.ts";

import { useLazySearchInWsQuery } from "../../api";
import { useGetColors } from "../../hooks";
import useSearchActions from "../../hooks/useSearchActions.ts";
import { CustomIcon } from "../get-icon.tsx";

import { AnalysisDropdown } from "./analysis-dropdown.tsx";
import { ProjectDropdown } from "./project-dropdown.tsx";
import { WorkspaceDropdown } from "./ws-dropdown.tsx";

const columnHelper = createColumnHelper<CommonItemsSchema>();
type CommonItemsType = WorkspaceSchema | AnalysesSchema | ProjectSchema;

export function SearchResultsDatatable(props: {
  onOpen: (item: CommonItemsSchema) => void;
  trigger: boolean;
  onTableScroll?: () => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { query } = useCustomQuery();
  const dispatch = useAppDispatch();

  const { getMenuItems } = useSearchActions();

  const { query: previewAnalysisId, setParam: _setParam } =
    useCustomQuery("analysis");
  const { getShortBgColors, getColors } = useGetColors();
  const [currentQuery, setCurrentQuery] = useState<string | null>(query);

  const [searchWS, { data, isFetching: isLoading, isLoading: isl }] =
    useLazySearchInWsQuery();
    
  const columns = useMemo(
    () => [
      columnHelper.accessor("itemData.name", {
        cell: ({ row: _row, getValue }) => {
          const type = _row.original?.itemType ?? CATEGORY.Workspace;
          const isSelected = previewAnalysisId === _row.original?.itemData?.id;

          return (
            <Flex className="items-center gap-1">
              <StyledIcon
                hideIcon={true}
                wrapperStyle={clsx(
                  "p-1 rounded-mg",
                  isSelected && "bg-orange-100",
                  getShortBgColors(type)
                )}
              >
                <CustomIcon
                  type={type}
                  color={isSelected ? "orange.800" : getColors(type)}
                />
              </StyledIcon>
              <Text color={isSelected ? "orange.800" : "gray.800"}>
                {getValue<string>()}
              </Text>
            </Flex>
          );
        },
        header: "Name",
        size: 50,
        enableSorting: true,
      }),
      columnHelper.accessor("itemType", {
        cell: ({ row: _row, getValue }) => (
          <Tag className="capitalize" colorScheme="gray">
            {getValue<string>()}
          </Tag>
        ),
        header: "Type",
        size: 15,
        enableSorting: true,
      }),
      columnHelper.accessor("itemData.updatedOn", {
        cell: (info) => getDateFormat(info.getValue() ?? ""),
        header: "Last Activity",
        size: 25,
      }),
      columnHelper.display({
        cell: (info) => {
          switch (info.row.original?.itemType) {
            case CATEGORY.Workspace:
              return (
                <WorkspaceDropdown
                  {...(info.row.original?.itemData as WorkspaceSchema)}
                />
              );
            case CATEGORY.Analysis:
              return (
                <AnalysisDropdown
                  {...(info.row.original?.itemData as AnalysesSchema)}
                />
              );
            case CATEGORY.Project:
            default:
              return (
                <ProjectDropdown
                  {...(info.row.original?.itemData as ProjectSchema)}
                />
              );
          }
        },
        size: 10,
        id: "actions",
      }),
    ],
    [props, data]
  );

  const table = useReactTable({
    columns: columns,
    data: data?.response?.data?.results ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 73,
    overscan: 5,
  });

  const pagination = useMemo(
    () => data?.response?.pagination ?? null,
    [data?.response?.pagination]
  );

  const paginate = React.useCallback(async () => {
    await searchWS({
      search: query ?? "",
      url: pagination && pagination.next ? pagination.next : null,
    });
    setIsSearching(false);
  }, [searchWS, query, pagination]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const reachedBottom = scrollHeight - scrollTop - clientHeight < 100;
        const canFetchMore =
          (!isLoading && data?.response?.pagination?.next) || !data;

        const shouldFetchMore = reachedBottom && canFetchMore;

        const shouldResetData = query !== currentQuery;
        if (shouldFetchMore || shouldResetData) {
          paginate();
        }
      }
    },
    [data, paginate, isLoading, query]
  );

  useEffect(() => {
    if (isLoading && query !== currentQuery) {
      setIsSearching(isLoading);
      setCurrentQuery(query ?? "");
    }
  }, [isLoading, query, currentQuery]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const handleContextMenu = React.useCallback(
    (e: React.MouseEvent, type: CATEGORY, dataset: CommonItemsType) => {
      e.preventDefault();
      dispatch(
        showContextMenu({
          x: e.clientX,
          y: e.clientY,
          items: getMenuItems(e, type, dataset),
        })
      );
    },
    [dispatch, getMenuItems]
  );

  if (isSearching) {
    return (
      <Flex className="h-full w-full items-center mt-10 justify-center gap-3">
        <Spinner size={"sm"} />
        <Box>Loading Results</Box>
      </Flex>
    );
  }

  const tableHeight = virtualizer.getTotalSize() + 50;

  return (
    <Box
      className="overflow-auto h-full grow"
      ref={tableContainerRef}
      onScroll={(e) => {
        fetchMoreOnBottomReached(e.target as HTMLDivElement);
        props.onTableScroll?.();
      }}
    >
      <Table width="100%" variant="unstyled">
        <Thead
          position="sticky"
          top={0}
          zIndex={1}
          bg={"white"}
          insetBlockStart={0}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr width={"100%"} key={headerGroup.id}>
              {headerGroup.headers.map((header, idx) => {
                const meta = header.column.columnDef.meta;
                return (
                  <Th
                    overflow={"hidden"}
                    className={clsx(
                      "group/header !h-full !py-4 !tracking-tight !font-semibold",
                      "hover:!bg-[#fcfcfc] !text-gray-700 !text-base !capitalize",
                      "before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px] before:bg-gray-200"
                    )}
                    style={{
                      width: `${header.column.columnDef.size}%`,
                    }}
                    textOverflow={"ellipsis"}
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    onClick={header.column.getToggleSortingHandler()}
                    role="button"
                  >
                    <chakra.span
                      className={clsx(
                        "inline-flex items-center",
                        isSearching && "w-full"
                      )}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanSort() && (
                        <chakra.span
                          pl="2"
                          className="text-gray-300 group-hover/header:text-gray-800"
                        >
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === "desc" ? (
                              <MdsArrowUpwardRound
                                strokeWidth={22}
                                size={14}
                                title="sorted descending"
                              />
                            ) : (
                              <MdsArrowDownwardRound
                                strokeWidth={22}
                                size={14}
                                title="sorted ascending"
                              />
                            )
                          ) : (
                            <MdsSwapVertRound
                              strokeWidth={22}
                              fontSize={16}
                              title="sort"
                            />
                          )}
                        </chakra.span>
                      )}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {!isl && (
          <Tbody
            style={{
              height: `${tableHeight}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
            width="100%"
            bg={"white"}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              const isSelected =
                previewAnalysisId === row.original?.itemData?.id;
              return (
                <Tr
                  // onClick={() => {
                  //   props.onOpen(row.original);
                  //   _setParam(row.original?.itemData?.id);
                  // }}
                  key={row.id}
                  width={"100%"}
                  _hover={{
                    bg: isSelected ? "orange.50" : "gray.50",
                  }}
                  bgColor={isSelected ? "orange.50" : "white"}
                  className="group absolute !flex items-center border-b"
                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                  onContextMenu={(e) =>
                    handleContextMenu(
                      e,
                      row?.original?.itemType,
                      row?.original?.itemData
                    )
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        overflow={"hidden"}
                        whiteSpace="pre"
                        textOverflow={"ellipsis"}
                        key={cell.id}
                        className="!grow-0 !shrink-0 !border-b-0"
                        style={{
                          width: `${cell.column.columnDef.size}%`,
                          maxWidth: `${cell.column.columnDef.size}%`,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
      {!isLoading && table.getRowModel().rows.length === 0 && (
        <div className="flex justify-center w-full items-center mt-[120px]">
          Try changing the search query for better results
        </div>
      )}
      {isLoading && (
        <Flex
          className={clsx(
            "justify-center py-1 text-gray-700 absolute bottom-0 shrink-0 w-full items-center gap-3 -translate-x-2 bg-white",
            isEmpty(data) ? "h-full" : "h-fit"
          )}
        >
          <Spinner size={"xs"} />
          <span>Loading</span>
        </Flex>
      )}
    </Box>
  );
}
