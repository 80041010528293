import { ModalCloseButton } from "@chakra-ui/react";
import React from "react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@/design/components/modal";
import { useAppDispatch } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import DeleteAccountBody from "./delete-account-body";

const DeleteAccountModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      isOpen={true}
      size={"xl"}
      closeOnOverlayClick={false}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent className="!py-4">
        <ModalHeader>Delete Account ?</ModalHeader>
        <ModalCloseButton className="!mr-0" />
        <ModalBody className="!px-6">
          <DeleteAccountBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAccountModal;
