import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdsSyncRound } from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "@/design/components/icon";
import {
  activeUser,
  EdaMetaDataContext,
  executeInProgress,
  FETCH_TYPE,
  IRefreshAPI,
  samplingAndStepsHash,
  setEdaMetaData,
  triggerFetch,
} from "@/features/data-transformation";
import {
  useGetReadonlyModeStatusQuery,
  useLazyGetReadonlyModeStatusQuery,
} from "@/features/data-transformation/api";
import { useReadOnlyMode } from "@/features/data-transformation/hooks";
import { useIsBackground } from "@/features/data-transformation/hooks/useIsBackground.ts";

import { EditingMessage } from "./editing-message.tsx";
import { PopoverMessageButton } from "./popover-message.tsx";

export const ReadOnly = () => {
  const dispatch = useDispatch();

  const { edaId, analysisId } = useContext(EdaMetaDataContext);
  const currentHash = useSelector(samplingAndStepsHash);
  const currentActiveUser = useSelector(activeUser);
  const { isReadOnlyEda: showReadonlyMessage } = useReadOnlyMode();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRefresh, setShowRefresh] = useState(false);
  const isBackground = useIsBackground();

  const { data } = useGetReadonlyModeStatusQuery(
    { edaId: edaId!, analysisId: analysisId! },
    {
      pollingInterval: 5000,
      skip: !showReadonlyMessage || !edaId,
    }
  );

  const [refreshAPI] = useLazyGetReadonlyModeStatusQuery();

  const updateStatus = (responseData: IRefreshAPI) => {
    dispatch(
      setEdaMetaData({
        accessMode: responseData.edaAccessMode,
        userHasWriteAccess: responseData.userHasWriteAccess,
        activeUser: responseData.activeUser,
      })
    );
  };

  const fetchStatus = async () => {
    try {
      const res = await refreshAPI({
        edaId: edaId!,
        analysisId: analysisId!,
      }).unwrap();
      const responseData = res.response.data!;
      updateStatus(responseData);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // Trigger for editing user
    const checkUserStatus = !isBackground && !showReadonlyMessage && edaId;

    if (checkUserStatus) {
      fetchStatus();
    }
  }, [isBackground]);

  useEffect(() => {
    if (!data) return;
    const responseData = data.response.data!;
    const newHash = responseData.stepAndSamplingHash;
    updateStatus(responseData);

    if (newHash) {
      const isDifferent = !!currentHash && newHash !== currentHash;
      const toggleButton = !showRefresh && isDifferent;

      if (toggleButton) {
        setShowRefresh(true);
      } else setShowRefresh(false);
    }
  }, [data, currentHash]);

  const onRefresh = () => {
    dispatch(triggerFetch(FETCH_TYPE.STEPS));
    setShowRefresh(false);
  };

  if (showReadonlyMessage)
    return (
      <Flex className="items-center">
        <EditingMessage user={currentActiveUser} />
        {showRefresh && (
          <Button
            className="mr-2"
            bgColor="green.500"
            colorScheme="secondary"
            iconSpacing="2px"
            onClick={onRefresh}
            rightIcon={<Icon as={MdsSyncRound} size="sm" />}
            size="sm"
            variant="solid"
          >
            Sync Changes
          </Button>
        )}
        <PopoverMessageButton
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
      </Flex>
    );
  return null;
};
