import { useMsal } from "@azure/msal-react";

const useAuth = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { idTokenClaims } = account!;
  const userEmail = idTokenClaims?.emails?.[0];

  return { msalInstance: instance, activeAccount: account, userEmail };
};

export default useAuth;
