import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { clsx } from "clsx";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { previewAnalysis } from "@/features/ws-manager";

import { AnalysisDatatable } from "./analysis-datatable.tsx";
import AnalysisDetails from "./analysis-details-panel.tsx";
import { AnalysisHeader } from "./analysis-header.tsx";
export const Analysis = () => {
  const { onOpen, onClose, isOpen } = useDisclosure({
    id: "analysisSidebar",
  });

  const { workspace, project } = useParams();

  const analysis = useSelector(previewAnalysis);
  useEffect(() => {
    if (analysis && !isOpen) {
      onOpen();
    }
    if (!analysis && isOpen) {
      onClose();
    }
  }, [analysis]);

  const key = `${workspace || "defaultWorkspace"}-${
    project || "defaultProject"
  }`;

  return (
    <Flex
      className="max-w-full w-full h-full p-4 pb-0 overflow-hidden"
      key={key}
      gap={isOpen ? "3" : 0}
    >
      <Helmet>
        <title>
          {isEmpty(analysis?.name)
            ? "Clootrack"
            : `${analysis?.name} - Clootrack`}
        </title>
      </Helmet>
      <Box
        className={clsx(
          "border border-gray-300 bg-white w-full h-full grow relative max-h-full overflow-y-auto"
        )}
      >
        <AnalysisHeader />
        <AnalysisDatatable onOpen={onOpen} label="Analysis" />
      </Box>
      <Box
        className={clsx(
          "h-[calc(100vh-100px)] xl:max-w-[30%] 2xl:max-w-[20vw] ease-in-out transition-transform duration-300 !sticky !top-0",
          isOpen && !isEmpty(analysis)
            ? "translate-x-0 w-[30%] overflow-auto flex-1 min-w-[300px] shrink-0"
            : "translate-x-full w-0 overflow-hidden"
        )}
      >
        {analysis && <AnalysisDetails onClose={onClose} />}
      </Box>
    </Flex>
  );
};
