import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createStandaloneToast } from "@chakra-ui/react";
import * as React from "react";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { AxiosMsalAuthProvider } from "@/auth/axios-auth-provider.tsx";
import { setAccount } from "@/slices/auth-slice.ts";

import ContextMenuManager from "./components/context-menu/context-menu-wrapper";
import { InitLoader } from "./components/loaders/InitLoader";
import AppRouter from "./routes";
import { PostAuthRouter } from "./routes/login-routes";

const { ToastContainer } = createStandaloneToast();

const App = () => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const activeAccount = instance.getActiveAccount();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const { hostname } = window.location;
  const parts = hostname.split(".");

  const router = parts.length === 5 ? PostAuthRouter : AppRouter;

  useEffect(() => {
    if (activeAccount) {
      dispatch(setAccount(activeAccount));
    }
  }, [activeAccount]);

  // TODO: Need to handle blank route
  return (
    <React.Suspense fallback={<InitLoader />}>
      <HelmetProvider>
        {isAuthenticated ? (
          <AxiosMsalAuthProvider>
            <RouterProvider router={router} fallbackElement={<InitLoader />} />
          </AxiosMsalAuthProvider>
        ) : (
          <></>
        )}
      </HelmetProvider>
      <ContextMenuManager />

      <ToastContainer />
    </React.Suspense>
  );
};

export default App;
