import { useMsal } from "@azure/msal-react";
import { Button, Flex } from "@chakra-ui/react";
import { clsx } from "clsx";
import React, { useContext, useEffect, useMemo } from "react";
import {
  MdsArrowBackRound,
  MdsTransformRound,
} from "react-icons-with-materialsymbols/mds";
import { useDispatch, useSelector } from "react-redux";

import { DatabaseFill } from "@/components/icons/database-fill.tsx";
import { PlayArrowFilled } from "@/components/icons/play-arrow.ts";
import { Icon } from "@/design/components/icon";
import {
  activeUser,
  EdaMetaDataContext,
  edaTitle,
  ExecutionProgress,
  FETCH_TYPE,
  ORIGIN,
  resetFetchTrigger,
  tableLoading,
  triggerSaveExecuteCall,
} from "@/features/data-transformation";
import { ReadOnly } from "@/features/data-transformation/components/top-bar/read-only.tsx";

import { useEdaActions } from "../../hooks/useEdaActions";
import { useTaskExecution } from "../../hooks/useTaskExecution";

export const DataTransformationBottomBar: React.FC = () => {
  const dispatch = useDispatch();

  const { edaFrom } = useContext(EdaMetaDataContext);
  const title = useSelector(edaTitle);
  const triggerExecute = useSelector(triggerSaveExecuteCall);
  const isTableLoading = useSelector(tableLoading);

  const {
    isExecuting,
    isLoadingExecute,
    taskMessage,
    requestId,
    setRequestId,
    onExecute,
  } = useTaskExecution();

  const {
    closeEdaPage,
    onSave,
    isSaving,
    showSaveButton,
    compiledSteps,
    hasErrorSteps,
  } = useEdaActions();

  const isTransformNode = edaFrom === ORIGIN.Workflow;

  useEffect(() => {
    dispatch(resetFetchTrigger(FETCH_TYPE.SAVE_EXECUTE));

    if (triggerExecute) {
      onExecute();
    }
  }, [triggerExecute]);

  const handleExecute = () => {
    if (hasErrorSteps()) return;
    onExecute();
  };

  const disableSaveExecute = useMemo(
    () => compiledSteps.length === 0 || isTableLoading,
    [compiledSteps, isTableLoading]
  );

  return (
    <Flex className="h-min min-w-full bg-white justify-between items-center border-b border-b-gray-300 px-2 py-2 pr-3 z-0">
      <Flex className="items-center gap-x-3">
        <Button
          sx={{
            ".chakra-button__icon": { color: "gray.900" },
            color: "gray.900",
          }}
          colorScheme="secondary"
          iconSpacing={0.5}
          leftIcon={
            <Icon as={MdsArrowBackRound} size="sm" color="text-gray-900" />
          }
          onClick={closeEdaPage}
          size="sm"
          variant="ghost"
        >
          Back
        </Button>
        {title && (
          <Flex className="items-center gap-2 font-medium leading-[16px] text-gray-900">
            <Flex
              className={clsx(
                "rounded bg-green-50 border border-green-100 p-0.5",
                isTransformNode
                  ? "bg-blue-50 border-blue-100"
                  : "bg-green-50 border-green-100"
              )}
            >
              <Icon
                as={isTransformNode ? MdsTransformRound : DatabaseFill}
                color={isTransformNode ? "blue.500" : "green.500"}
                size="lg"
              />
            </Flex>
            {title}
          </Flex>
        )}
      </Flex>
      <Flex className="items-center gap-x-3">
        <ReadOnly />
        {isExecuting && (
          <ExecutionProgress
            message={taskMessage}
            requestId={requestId}
            setRequestId={setRequestId}
          />
        )}
        {showSaveButton && (
          <>
            <Button
              colorScheme="secondary"
              isDisabled={isTableLoading}
              isLoading={isSaving}
              onClick={onSave}
              size="sm"
              variant="outline"
            >
              Save
            </Button>
            <Button
              colorScheme="secondary"
              iconSpacing="2px"
              isDisabled={disableSaveExecute}
              isLoading={isLoadingExecute}
              onClick={handleExecute}
              rightIcon={<Icon as={PlayArrowFilled} size="sm" />}
              size="sm"
              variant="solid"
            >
              Save & Execute
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};
