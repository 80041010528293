import { Flex, Tag, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { useMemo } from "react";

import { ADD_DATASET_TAGS } from "@/utils/enums.ts";

type DatasetBlockProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  tag: ADD_DATASET_TAGS;
  onClick?: () => void;
};
export const DatasetBlock = ({
  icon,
  title,
  description,
  tag,
  onClick,
}: DatasetBlockProps) => {
  const tagColor = useMemo(() => {
    switch (tag) {
      case ADD_DATASET_TAGS.FILE:
        return "pink";
      case ADD_DATASET_TAGS.AUTH_REQUIRED:
        return "teal";
    }
  }, [tag]);
  return (
    <Flex
      className={clsx(
        "flex w-full h-fit gap-3 p-[12px] items-center border border-transparent select-none",
        "hover:border-gray-200 hover:bg-[#fcfcfc] rounded cursor-pointer",
        "focus:border-[#E0E0E0] focus:bg-[#f2f2f2] active:border-[#E0E0E0] active:bg-[#f2f2f2]"
      )}
      onClick={onClick}
    >
      <Flex className="items-center justify-center h-[48px] w-[48px]">
        {icon}
      </Flex>
      <Flex className="flex-col gap-1 pb-0.5 justify-start">
        <Text className="font-medium leading-tigh text-gray-800">{title}</Text>
      </Flex>
    </Flex>
  );
};
