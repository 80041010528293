import { CircularProgress, Flex, Text } from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import React, { Suspense } from "react";

import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";

import AskClootrackLayout from "./AskClootrack/askclootrackLayout";

(window as any).baseUrl = getMSBaseUrl(MICRO_SERVICES.INSIGHTS);
(window as any).pdsBaseUrl = getMSBaseUrl(MICRO_SERVICES.PDS);

const AskClootrackRemote = React.lazy(() =>
  import("remoteApp/App").catch((err) => {
    console.error("Failed to load AskClootrack module:", err);
    return { default: () => <div>Error loading AskClootrack</div> };
  })
);

export const Loader = () => {
  return (
    <Flex className="w-full h-screen items-center justify-center gap-3">
      <CircularProgress color="brand.500" isIndeterminate size="30px" />
      <Text className="text-lg">Loading...</Text>
    </Flex>
  );
};

export const AskClooTrackWrapper: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <AskClootrackLayout>
          <AskClootrackRemote />
        </AskClootrackLayout>
      </Suspense>
    </ErrorBoundary>
  );
};
