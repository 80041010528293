import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import usePermissions from "@/hooks/usePermissions";
import { Loader } from "@/routes/askclootrack.tsx";
import { CATEGORY, PERMISSIONS } from "@/utils/enums";
import { CacheKey, cacheUtil } from "@/utils/localstorage.ts";

const AskClootrackLayout = ({ children }: { children: React.ReactNode }) => {
  const { checkPermission, hasFetchedPermission } = usePermissions();
  const params = useParams();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  useEffect(() => {
    if (account) {
      (window as any).account = account;
      const tenantToken = cacheUtil.getUnParsed(CacheKey.TenantId) ?? {};
      const userId = cacheUtil.getUnParsed(CacheKey.UserId) ?? {};
      const baseHeaders = {
        Authorization: `Bearer ${account?.idToken}`,
        "X-TENANT-ID": tenantToken,
        "X-USER-ID": userId,
      };
      (window as any).baseHeaders = baseHeaders;
    }
  }, [account]);

  // Function to add properties to the window object
  const addPropertiesToWindow = () => {
    const canEditSettings = checkPermission({
      id: params.analysisId,
      requiredPermission: PERMISSIONS.WRITE,
      type: CATEGORY.Analysis,
    });
    const canViewSettings = checkPermission({
      id: params.analysisId,
      requiredPermission: PERMISSIONS.READ,
      type: CATEGORY.Analysis,
    });
    return { canEditSettings, canViewSettings };
  };
  if (hasFetchedPermission) {
    (window as any).sharedAnalysisPersmissions = addPropertiesToWindow;

    return <>{children}</>;
  }

  return <Loader />;
};

export default AskClootrackLayout;
