import {
  Button,
  Input,
  Center,
  Flex,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

async function parseJSON(response: Response) {
  return await response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}

const RegionSelection = () => {
  const [email, setEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [availableRegions, setAvailableRegions] = useState<string[]>([]);
  const [region, setRegion] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const code = import.meta.env.VITE_LOGIN_API_CODE;

  const handleContinue = () => {
    if (!email) {
      setErrorMessage("Email is required");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage("Email is invalid");
      return;
    }
    setIsVerifying(true);

    fetch(
      `https://default-webapp-endpoint-315ecea3-b2g6gydzh4gydnft.a02.azurefd.net/api/retrieve_regions?email=${email}&code=${code}`
    )
      .then(parseJSON)
      .then((data) => {
        if (data.ok) {
          const regions = (data.json?.regions as string[]) || [];
          setAvailableRegions(regions);
          setRegion(regions[0]);
          setIsEmailVerified(true);
        } else {
          setErrorMessage(
            (data.json?.message as string) || "Something went wrong"
          );
        }
        setIsVerifying(false);
      })
      .catch(() => {
        setErrorMessage("Something went wrong. Could not verify email");
        setIsVerifying(false);
      });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRegion(e.target.value);
    setErrorMessage("");
  };

  const handleLogin = (selectedRegion?: string) => {
    console.log(selectedRegion, region);
    window.location.href = `https://${selectedRegion ?? region}.${
      window.location.hostname
    }?login_hint=${email}`;
  };

  const handleInputDoubleClick = () => {
    setRegion("");
    setAvailableRegions([]);
    setIsEmailVerified(false);
    setErrorMessage("");
  };

  return (
    <Center
      h="100vh"
      style={{
        backgroundImage:
          "url('https://gen2neopoc.blob.core.windows.net/user-mgmt-test/bg%20(1).png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Flex className="flex-col min-h-[80%] w-[30vw] min-w-[400px] p-4 px-[48px] text-center bg-white border border-gray-300 rounded-lg shadow-lg">
        <Flex className="justify-center mt-10">
          <img
            src="https://aadcdn.msftauthimages.net/dbd5a2dd-7-bfcjwtyhaftvanjcd1xsj-7hp1amlbafbqman4tja/logintenantbranding/0/bannerlogo?ts=638569665983860206"
            alt="Clootrack Logo"
            className="w-1/2 h-auto"
          />
        </Flex>
        <Flex className="justify-center mt-10">
          <Text className="text-4xl font-light">Region Selection</Text>
        </Flex>

        <Flex className="flex-col gap-4 grow mt-10">
          <FormControl isInvalid={!!errorMessage}>
            <FormLabel className="pl-2 mb-1">Email</FormLabel>
            <Input
              className={`w-full border p-3 rounded-sm disabled:cursor-not-allowed disabled:text-gray-600 ${
                errorMessage ? "border-red-500 outline-none" : "border-gray-500"
              }`}
              isDisabled={isVerifying}
              isReadOnly={isVerifying || availableRegions.length > 0}
              name="email"
              onChange={handleEmailChange}
              onDoubleClick={handleInputDoubleClick}
              value={email}
            />
            <FormErrorMessage className="break-all text-sm text-red-500">
              {errorMessage}
            </FormErrorMessage>
          </FormControl>
          {isEmailVerified && (
            <FormControl>
              <FormLabel className="pl-2 mb-1">Select Region</FormLabel>
              <select
                onChange={handleCountryChange}
                value={region}
                className="w-full border p-3 rounded-sm border-gray-500"
              >
                {availableRegions.map((r) => (
                  <option key={r} value={r}>
                    {r.toUpperCase()}
                  </option>
                ))}
              </select>
            </FormControl>
          )}
          {isEmailVerified ? (
            <Button
              className="w-fit self-end !bg-black-900 py-2 px-4 rounded-sm text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
              isDisabled={isVerifying}
              isLoading={isVerifying}
              onClick={() => handleLogin()}
            >
              Login
            </Button>
          ) : (
            <Button
              className="w-fit self-end !bg-black-900 py-2 px-4 rounded-sm text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
              isDisabled={isVerifying}
              isLoading={isVerifying}
              onClick={handleContinue}
            >
              Continue
            </Button>
          )}
        </Flex>
      </Flex>
    </Center>
  );
};

export default RegionSelection;
