import {
  Box,
  chakra,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import {
  MdsCloseSharp,
  MdsSearchSharp,
  MdsTableRound,
} from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { DoneFill } from "@/components/icons/done-filled.tsx";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@/design/components/data-table";

import { useSyncSourceFileMutation } from "../api";
import { SourceListFilesFooter } from "../modals/add-dataset/source-list-files-footer.tsx";
// import { DatasetsTableDropdown } from "./datasets-table-dropdown.tsx";
interface DatasetsSchema {
  name: string;
  schema: string;
  config: any;
  filefield: string;
  [key: string]: any;
}
const columnHelper = createColumnHelper<DatasetsSchema>();

export const stripName = (name: string) => {
  if (!name) return "";
  const neoIndex = name.indexOf("__neo");
  if (neoIndex !== -1) {
    name = name.substring(0, neoIndex);
  }
  return name.split(".")[0];
};

export const getDatasetIcon = (type: string) => {
  switch (type) {
    case "output":
      return "text-brand-500 bg-brand-50";
    case "input":
      return "text-green-500 bg-green-50";
    default:
      return "text-gray-800 bg-gray-50";
  }
};

export function SourceFilesList({
  ioId,
  setActiveStep,
  setformState,
  catalog,
}: {
  ioId: string | undefined;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setformState: React.Dispatch<React.SetStateAction<any>>;
  catalog: any;
}) {
  const { analysisId } = useParams();
  const navigate = useNavigate();
  const [submitFileAPI1, { isLoading: isSubmitting }] =
    useSyncSourceFileMutation();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search, setSearch] = React.useState("");
  const [isSearching, setIsSearching] = React.useState(false);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  // change this back to array when multiple file selection are supported
  // const [selectedFiles, setselectedFiles] = React.useState<any[]>([]);

  const [selectedFile, setselectedFile] = React.useState<any>();

  const data = useMemo(() => {
    const streams = catalog.streams.map(
      (file: { stream: { name: any }; config: any }) => {
        return {
          name: file.stream.name,
          schema: "JSON",
          filefield: "All",
          config: file,
        };
      }
    );
    if (!search) return streams;
    const filteredStreams = streams.filter(
      (s) => s?.name.toLowerCase().includes(search.toLowerCase())
    );
    return filteredStreams;
  }, [catalog, search]);

  useEffect(() => {
    if (data && data.length > 0 && !selectedFile) {
      setselectedFile(data?.[0].config);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: ({ row: _row, getValue }) => {
          const addedFile = selectedFile?.stream?.name == _row.original.name;
          const filename = stripName(getValue<string>());
          return (
            <Tooltip
              isDisabled={filename.length < 20}
              label={filename}
              placement="bottom-start"
            >
              <Flex className="justify-between items-center">
                <Flex className="items-center gap-2">
                  <Box
                    className={clsx(
                      "grid shrink-0 bg-gray-50 place-items-center h-9 w-9 rounded"
                    )}
                  >
                    <Icon as={MdsTableRound} fontSize={24} strokeWidth={22} />
                  </Box>
                  <Text className="line-clamp-1 overflow-hidden break-all">
                    {filename}
                  </Text>
                </Flex>
                {addedFile && (
                  <Box className="flex-shrink-0 text-brand-500 h-5 w-5 grid rounded-full place-items-center">
                    <Icon as={DoneFill} fontSize={23} />
                  </Box>
                )}
              </Flex>
            </Tooltip>
          );
        },
        header: () => <span className="select-none">Name</span>,
        size: 100,
        enableSorting: !isSearching,
      }),
    ],
    [isSearching, selectedFile]
  );

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSearch("");
    setIsSearching((state) => !state);
  };

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isSearching) {
        setIsSearching(false);
        setSearch("");
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearching]);

  const handleRowClick = (fileStreamConfig: any) => {
    if (isSubmitting) return;
    setselectedFile((prevFile) => {
      if (prevFile?.stream?.name === fileStreamConfig.stream.name) {
        return null;
      } else {
        return fileStreamConfig;
      }
    });
  };

  const onUpload = async () => {
    const fileStreamConfig = selectedFile;
    try {
      const body = {
        sync_catalog: {
          streams: [fileStreamConfig],
        },
      };
      await submitFileAPI1({
        analysisId: analysisId ?? "",
        ioId: ioId ?? "",
        body: body,
      }).unwrap();
      setformState(undefined);
      navigate(`/analysis/${analysisId}/data-manager/uploads`);
    } catch (e) {
      console.log(e, "eerorr");
    }
  };

  const table = useReactTable({
    columns: columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 70,
    overscan: 5,
  });

  const onCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // navigate(-1);
    setselectedFile(undefined);
    // setformState(undefined);
    // setActiveStep(0);
  };
  return (
    <Flex className="flex-col h-full overflow-hidden mb-14">
      <Box
        className={clsx("overflow-auto h-full min-h-[400px]")}
        ref={tableContainerRef}
        // onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      >
        {/* <Box
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
        > */}
        <Table
          width="100%"
          variant="simple"
          __css={{
            borderCollapse: "separate",
            borderSpacing: 0,
            position: "relative",
            "&::after": {
              display: "block",
              content: "''",
              // this ensures the table takes up as much size as the total elements by adding padding to the end
              height: `${
                virtualizer.getTotalSize() -
                (virtualizer?.scrollRect?.height ?? 0)
              }px`,
            },
          }}
        >
          <Thead
            position="sticky"
            top={0}
            zIndex={1}
            h={"50px"}
            bg={"white"}
            insetBlockStart={0}
          >
            <Box
              className={clsx(
                "absolute top-1 left-0 flex bg-white",
                isSearching ? "w-[50%] z-10" : "w-min"
              )}
            >
              <IconButton
                color="gray.900"
                aria-label="search"
                colorScheme="blackAlpha"
                icon={
                  isSearching ? (
                    <MdsCloseSharp size={18} title="search" />
                  ) : (
                    <MdsSearchSharp size={18} title="search" />
                  )
                }
                onClick={onSearchClick}
                variant={"ghost"}
              />
              {isSearching && (
                <Input
                  className="text-gray-800 !bg-white font-normal mr-2"
                  autoFocus={true}
                  name="search"
                  onChange={handleInputChange}
                  placeholder="Search by name"
                  value={search}
                />
              )}
            </Box>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  const meta = header.column.columnDef.meta;
                  return (
                    <Th
                      width={`${header.column.getSize()}%`}
                      maxWidth={`${header.column.getSize()}%`}
                      className={clsx(
                        "overflow-hidden text-ellipsis border-b-[1px] border-gray-200 !py-1",
                        idx === 0 && "!pl-10"
                      )}
                      key={header.id}
                      h={"50px"}
                      isNumeric={meta?.isNumeric}
                      onClick={header.column.getToggleSortingHandler()}
                      role="button"
                    >
                      <Flex align="center">
                        <chakra.span
                          className={clsx(
                            "inline-flex items-center",
                            isSearching && "w-full"
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </chakra.span>
                        {header.column.getCanSort() && (
                          <chakra.span pl="4">
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown
                                  aria-label="sorted descending"
                                  title="sorted descending"
                                />
                              ) : (
                                <BsArrowUp
                                  aria-label="sorted ascending"
                                  title="sorted ascending"
                                />
                              )
                            ) : (
                              <BsArrowDownUp aria-label="sort" title="sort" />
                            )}
                          </chakra.span>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody className="font-medium overflow-hidden max-w-full">
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              const isSelected =
                selectedFile?.stream?.name == row.original.name;
              return (
                <Tr
                  key={row.id}
                  _hover={{
                    cursor: "pointer",
                  }}
                  className={clsx(
                    "overflow-hidden max-w-full hover:bg-gradient-to-r hover:from-white  hover:to-gray-50",
                    isSelected && "bg-gradient-to-r from-white  !to-[#FFF0E8]"
                  )}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                  }}
                  onClick={() => {
                    handleRowClick(row.original.config);
                  }} // Replace 'name' with the desired accessor key
                >
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    return (
                      <Td
                        border={0}
                        borderRight={2}
                        key={cell.id}
                        className={clsx(
                          "font-medium !grow-0 !shrink-0",
                          cellIndex == 0 &&
                            "pl-7 hover:before:absolute hover:before:inset-y-0 hover:before:left-0 hover:before:w-1 hover:before:bg-orange-400 relative before:content-['']",
                          isSelected &&
                            "pl-7 before:absolute before:inset-y-0 before:left-0 before:w-1 before:bg-orange-400 relative before:content-['']"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* </Box> */}

        {table.getRowModel().rows.length === 0 && (
          <Flex className="justify-center w-full items-center mt-32">
            No Data
          </Flex>
        )}
      </Box>

      <Box
        className={clsx(
          "absolute w-full transition-all left-0",
          selectedFile ? "bottom-0" : "-bottom-20"
        )}
      >
        {selectedFile && (
          <SourceListFilesFooter
            onCancel={onCancel}
            onUpload={onUpload}
            isLoading={isSubmitting}
            selectedFiles={selectedFile}
          />
        )}
      </Box>
    </Flex>
  );
}
