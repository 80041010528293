import { DATASET_USAGE } from "@/utils/enums";

export const DATASET_USAGE_MESSAGE: Record<DATASET_USAGE, string> = {
  [DATASET_USAGE.EDA]:
    "This dataset in being transformed. Are you sure you want to delete it permanently?",
  [DATASET_USAGE.INSIGHTS]:
    "This dataset has been enabled in Genie. Are you sure you want to delete it permanently?",
  [DATASET_USAGE.WORKFLOW]:
    "This dataset is being used in your workflow. Are you sure you want to delete it permanently?",
};

export enum AIRBYTE_CONNECTOR_SOURCES {
  GOOGLE = "google",
  ONEDRIVE = "onedrive",
  DROPBOX = "dropbox",
}

export enum AIRBYTE_SOURCES {
  LOCAL_FILE_UPLOAD = "local_file_upload",
  CUSTOM_SWIFT_GOOGLE_DRIVE = "custom_swift_google_drive",
  CUSTOM_SWIFT_SFTP = "custom_swift_sftp",
  WEBSCRAPER_API = "webscraper_api",
  ZENDESK_SUPPORT = "zendesk_support",
  ZENDESK_TALK = "zendesk_talk",
}
