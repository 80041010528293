import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  ACCESS_MODE,
  activeUser,
  edaAccessMode,
  executeInProgress,
  userHasWriteAccess,
} from "@/features/data-transformation";

export const useReadOnlyMode = () => {
  const canWrite = useSelector(userHasWriteAccess);
  const mode = useSelector(edaAccessMode);
  const isExecuting = useSelector(executeInProgress);
  const currentActiveUser = useSelector(activeUser);

  const canEdit = useMemo(() => mode === ACCESS_MODE.READ_WRITE, [mode]);

  const isReadMode = useMemo(() => mode === ACCESS_MODE.READ, [mode]);

  const hasWriteAccess = !isReadMode && canWrite;

  const isMounted = useMemo(() => mode !== null, [mode]);

  const isReadOnlyEda =
    (!canWrite && !isExecuting && isMounted) || currentActiveUser == null;

  return { canEdit, isMounted, canWrite, hasWriteAccess, isReadOnlyEda };
};
