import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { MdsInfoRound } from "react-icons-with-materialsymbols/mds";

import useAuth from "@/hooks/useAuth";

const DeleteOTP = () => {
  const { userEmail } = useAuth();
  return (
    <Flex className="flex-col gap-5">
      <Box>
        Please enter the OTP sent to your email <b>{userEmail}</b> to
        complete&nbsp;
        <b className="text-red-500">Account Deletion.</b>
      </Box>
      <form action="#">
        <FormControl isRequired>
          <FormLabel>Enter OTP</FormLabel>
          <HStack
            className="border border-gray-300 rounded"
            justify={"space-between"}
          >
            <Box>
              <PinInput otp placeholder="_">
                <PinInputField className="!border-none border-b" />
                <PinInputField className="!border-none border-b" />
                <PinInputField className="!border-none border-b" />
                <PinInputField className="!border-none border-b" />
                <PinInputField className="!border-none border-b" />
                <PinInputField className="!border-none border-b" />
              </PinInput>
            </Box>
            <Button
              className="!no-underline"
              colorScheme="dark"
              variant={"link"}
            >
              Resent OTP
            </Button>
          </HStack>
          <FormHelperText className="flex gap-1 ml-2 items-center">
            <MdsInfoRound fontSize={16} />
            OTP is valid for 5 mins
          </FormHelperText>
        </FormControl>
        <Button className="mt-8 float-right" colorScheme="customRed">
          Delete My Account
        </Button>
      </form>
    </Flex>
  );
};

export default DeleteOTP;
