import { useMsal } from "@azure/msal-react";
import { CircularProgress, Flex, Text, Link, Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdUnfoldMore } from "react-icons/md";
import {
  MdsCheckRound,
  MdsDomainRound,
} from "react-icons-with-materialsymbols/mds";

import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { useGetOrgsForUserQuery } from "@/features/user-manager";
import { IOrganizationSchema } from "@/features/user-manager/types";

export const OrgDropdown = () => {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const { idTokenClaims } = account!;
  const userEmail = idTokenClaims?.emails?.[0];

  const host = window.location.host;
  const currentOrgPrefix = host.split(".")[0];
  const baseUrl = host.split(".").slice(2).join(".");

  const { data, isLoading } = useGetOrgsForUserQuery(
    {
      email: userEmail || "",
    },
    { skip: isEmpty(userEmail) }
  );

  const { currentOrg, orgList } = useMemo(() => {
    let selectedorg: IOrganizationSchema | undefined;
    const orgs = data?.response?.data?.organisations || [];

    orgs.forEach((org) => {
      if (org.ordDomainPrefix === currentOrgPrefix) {
        selectedorg = org;
      }
    });
    return { currentOrg: selectedorg, orgList: orgs };
  }, [data]);

  const closeSubmenu = () => {
    if (submenuOpen) {
      setSubmenuOpen(false);
    }
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const getUrl = (org: IOrganizationSchema) => {
    return `https://${org.ordDomainPrefix}.${org.orgRegion}.${baseUrl}`;
  };

  return (
    <Flex className="flex-col w-full p-3">
      <Text className="text-[13px] mb-1.5 text-gray-600 ">Organization</Text>
      {isLoading ? (
        <Flex className="items-center gap-2 border p-2 rounded bg-gray-100">
          <CircularProgress color="gray.700" isIndeterminate size={5} />
          <Text className="text-sm">Loading Orgs</Text>
        </Flex>
      ) : (
        <Menu isOpen={submenuOpen} placement="bottom" onClose={closeSubmenu}>
          <MenuButton
            onClick={toggleSubmenu}
            className="!cursor-pointer"
            disabled={isLoading || !data}
          >
            <Flex className="flex flex-row w-full justify-start items-center border border-gray-300 rounded-md p-2.5 gap-1.5 select-none">
              <Icon as={IoShareSocialOutline} />
              <Text className="text-[15px] grow text-left font-medium tracking-tight truncate max-w-[10rem]">
                {currentOrg?.tenantName || "Select Organization"}
              </Text>
              <Icon as={MdUnfoldMore} />
            </Flex>
          </MenuButton>
          <MenuList className="mr-7 shadow-xl -mt-2 w-[15rem]">
            {orgList.map((org) => {
              const isCurrentOrg = currentOrgPrefix === org.ordDomainPrefix;
              return (
                <MenuItem
                  key={org.orgName}
                  as={Link}
                  className="hover:!no-underline"
                  target={isCurrentOrg ? "_self" : "_blank"}
                  href={isCurrentOrg ? undefined : getUrl(org)}
                  icon={
                    <Icon
                      as={MdsDomainRound}
                      bg={"gray.200"}
                      size={"lg"}
                      className="rounded-full p-1 stroke-[22]"
                    />
                  }
                >
                  <Flex className="items-center">
                    <Box className="grow">{org.orgName}</Box>
                    {currentOrgPrefix === org.ordDomainPrefix && (
                      <MdsCheckRound strokeWidth={22} />
                    )}
                  </Flex>
                </MenuItem>
              );
            })}
            {/* <MenuItem key={org.orgName} icon={<Icon as={IoMdAdd} />}>
              Create new Organization
            </MenuItem> */}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
