import { Button, Flex, ModalCloseButton, ModalFooter } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const DeleteStepConfirmation = () => {
  const dispatch = useDispatch();

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const onDelete = () => {
    modalProps.onDelete?.();
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-440 !pb-0 !gap-y-0.5">
        <Flex className="relative px-5">
          <Flex className="!font-normal !text-lg !text-gray-900">
            Delete Step?
          </Flex>
          <ModalCloseButton className="!mr-0 !-top-1" />
        </Flex>
        <ModalBody className="!px-5 !pb-4 !py-0 text-sm w-fit leading-[21px] text-gray-800">
          <Flex className="flex-col text-sm leading-[140%] text-gray-800">
            Deleting a step might lead to re-executions on all the following
            steps. Are you sure you wish to delete this step?
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex className="!p-6 w-full justify-between">
            <Button
              colorScheme="secondary"
              onClick={onClose}
              size="sm"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              bgColor="red.600"
              colorScheme="primary"
              loadingText="Saving steps"
              onClick={onDelete}
              size="sm"
              variant="solid"
            >
              Delete Step
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
