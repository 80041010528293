export const LogoWithText = ({
  height = "44",
  width = "152",
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 152 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7136 24.1486C25.6044 24.2642 25.4512 24.3266 25.2922 24.3266H13.6401C13.4811 24.3266 13.3278 24.2642 13.2186 24.1486C11.8001 22.6465 10.9299 20.6295 10.9299 18.4145C10.9299 13.7664 14.7468 10 19.4635 10C24.1803 10 28.0023 13.7664 28.0023 18.4145C28.0023 20.6295 27.1321 22.6465 25.7136 24.1486ZM24.6457 29.7341H14.2813C13.7744 29.7341 13.3607 29.3314 13.3607 28.8269V27.0213C13.3607 26.6899 13.6293 26.4213 13.9607 26.4213H24.9611C25.2925 26.4213 25.5611 26.6899 25.5611 27.0213V28.8269C25.5663 29.3263 25.1526 29.7341 24.6457 29.7341ZM22.5046 33.9998H16.4224C15.9156 33.9998 15.5019 33.5972 15.5019 33.0926V32.4287C15.5019 32.0973 15.7705 31.8287 16.1019 31.8287H22.82C23.1514 31.8287 23.42 32.0973 23.42 32.4287V33.0926C23.4252 33.5921 23.0114 33.9998 22.5046 33.9998Z"
        fill="#F4673B"
      />
      <path
        d="M45.6773 31.3875C43.9273 31.3875 42.4023 31.0042 41.1023 30.2375C39.8023 29.4542 38.794 28.3625 38.0773 26.9625C37.3606 25.5458 37.0023 23.8958 37.0023 22.0125C37.0023 20.1292 37.369 18.4875 38.1023 17.0875C38.8356 15.6708 39.869 14.5708 41.2023 13.7875C42.5356 13.0042 44.0773 12.6125 45.8273 12.6125C47.2273 12.6125 48.4856 12.8708 49.6023 13.3875C50.7356 13.9042 51.669 14.6375 52.4023 15.5875C53.1356 16.5208 53.6106 17.6292 53.8273 18.9125H50.8523C50.519 17.7792 49.9023 16.8958 49.0023 16.2625C48.1023 15.6292 47.0106 15.3125 45.7273 15.3125C44.5606 15.3125 43.5356 15.5875 42.6523 16.1375C41.7856 16.6875 41.1106 17.4625 40.6273 18.4625C40.1606 19.4625 39.9273 20.6458 39.9273 22.0125C39.9273 23.3458 40.169 24.5208 40.6523 25.5375C41.1356 26.5375 41.8106 27.3125 42.6773 27.8625C43.544 28.4125 44.5606 28.6875 45.7273 28.6875C47.0106 28.6875 48.119 28.3708 49.0523 27.7375C49.9856 27.1042 50.619 26.2542 50.9523 25.1875H53.8773C53.644 26.4208 53.144 27.5042 52.3773 28.4375C51.6106 29.3708 50.6523 30.0958 49.5023 30.6125C48.3523 31.1292 47.0773 31.3875 45.6773 31.3875Z"
        fill="#F4673B"
      />
      <path
        d="M58.2302 31.0875H55.5802V12.5875H58.2302V31.0875Z"
        fill="#F4673B"
      />
      <path
        d="M60.0599 24.9375C60.0599 23.6542 60.3349 22.5292 60.8849 21.5625C61.4516 20.5958 62.2266 19.8375 63.2099 19.2875C64.1933 18.7375 65.3016 18.4625 66.5349 18.4625C67.7849 18.4625 68.8933 18.7375 69.8599 19.2875C70.8433 19.8375 71.6099 20.5958 72.1599 21.5625C72.7266 22.5292 73.0099 23.6542 73.0099 24.9375C73.0099 26.2042 72.7266 27.3208 72.1599 28.2875C71.6099 29.2542 70.8433 30.0125 69.8599 30.5625C68.8933 31.1125 67.7849 31.3875 66.5349 31.3875C65.3016 31.3875 64.1933 31.1125 63.2099 30.5625C62.2266 30.0125 61.4516 29.2542 60.8849 28.2875C60.3349 27.3208 60.0599 26.2042 60.0599 24.9375ZM62.7599 24.9125C62.7599 25.7125 62.9183 26.4208 63.2349 27.0375C63.5516 27.6542 63.9933 28.1375 64.5599 28.4875C65.1266 28.8208 65.7849 28.9875 66.5349 28.9875C67.2849 28.9875 67.9433 28.8208 68.5099 28.4875C69.0766 28.1375 69.5183 27.6542 69.8349 27.0375C70.1516 26.4208 70.3099 25.7125 70.3099 24.9125C70.3099 24.1125 70.1516 23.4125 69.8349 22.8125C69.5183 22.1958 69.0766 21.7208 68.5099 21.3875C67.9433 21.0375 67.2849 20.8625 66.5349 20.8625C65.7849 20.8625 65.1266 21.0375 64.5599 21.3875C63.9933 21.7208 63.5516 22.1958 63.2349 22.8125C62.9183 23.4125 62.7599 24.1125 62.7599 24.9125Z"
        fill="#F4673B"
      />
      <path
        d="M74.0072 24.9375C74.0072 23.6542 74.2822 22.5292 74.8322 21.5625C75.3989 20.5958 76.1739 19.8375 77.1572 19.2875C78.1405 18.7375 79.2489 18.4625 80.4822 18.4625C81.7322 18.4625 82.8405 18.7375 83.8072 19.2875C84.7905 19.8375 85.5572 20.5958 86.1072 21.5625C86.6739 22.5292 86.9572 23.6542 86.9572 24.9375C86.9572 26.2042 86.6739 27.3208 86.1072 28.2875C85.5572 29.2542 84.7905 30.0125 83.8072 30.5625C82.8405 31.1125 81.7322 31.3875 80.4822 31.3875C79.2489 31.3875 78.1405 31.1125 77.1572 30.5625C76.1739 30.0125 75.3989 29.2542 74.8322 28.2875C74.2822 27.3208 74.0072 26.2042 74.0072 24.9375ZM76.7072 24.9125C76.7072 25.7125 76.8655 26.4208 77.1822 27.0375C77.4989 27.6542 77.9405 28.1375 78.5072 28.4875C79.0739 28.8208 79.7322 28.9875 80.4822 28.9875C81.2322 28.9875 81.8905 28.8208 82.4572 28.4875C83.0239 28.1375 83.4655 27.6542 83.7822 27.0375C84.0989 26.4208 84.2572 25.7125 84.2572 24.9125C84.2572 24.1125 84.0989 23.4125 83.7822 22.8125C83.4655 22.1958 83.0239 21.7208 82.4572 21.3875C81.8905 21.0375 81.2322 20.8625 80.4822 20.8625C79.7322 20.8625 79.0739 21.0375 78.5072 21.3875C77.9405 21.7208 77.4989 22.1958 77.1822 22.8125C76.8655 23.4125 76.7072 24.1125 76.7072 24.9125Z"
        fill="#F4673B"
      />
      <path
        d="M87.3324 18.8125H94.7824V21.0375H87.3324V18.8125ZM92.3824 31.0875H89.7324V14.9625H92.3824V31.0875Z"
        fill="#F4673B"
      />
      <path
        d="M103.386 18.7375V21.2125H102.311C101.211 21.2125 100.336 21.5208 99.6855 22.1375C99.0355 22.7542 98.7105 23.6542 98.7105 24.8375V31.0875H96.0605V18.8125H98.5605L98.7855 21.3375H98.5355C98.7022 20.5208 99.0938 19.8625 99.7105 19.3625C100.344 18.8458 101.144 18.5875 102.111 18.5875C102.327 18.5875 102.536 18.5958 102.736 18.6125C102.936 18.6292 103.152 18.6708 103.386 18.7375Z"
        fill="#F4673B"
      />
      <path
        d="M107.895 31.4125C106.595 31.4125 105.57 31.0708 104.82 30.3875C104.087 29.6875 103.72 28.7708 103.72 27.6375C103.72 26.5042 104.112 25.6042 104.895 24.9375C105.695 24.2542 106.828 23.8542 108.295 23.7375L112.045 23.4375V23.1375C112.045 22.5042 111.928 22.0042 111.695 21.6375C111.478 21.2542 111.162 20.9792 110.745 20.8125C110.345 20.6458 109.87 20.5625 109.32 20.5625C108.37 20.5625 107.637 20.7542 107.12 21.1375C106.62 21.5208 106.37 22.0625 106.37 22.7625H104.07C104.07 21.8792 104.287 21.1208 104.72 20.4875C105.17 19.8375 105.803 19.3375 106.62 18.9875C107.437 18.6375 108.378 18.4625 109.445 18.4625C110.512 18.4625 111.428 18.6458 112.195 19.0125C112.978 19.3792 113.587 19.9375 114.02 20.6875C114.453 21.4375 114.67 22.3792 114.67 23.5125V31.0875H112.345L112.12 29.1625C111.82 29.8458 111.287 30.3958 110.52 30.8125C109.753 31.2125 108.878 31.4125 107.895 31.4125ZM108.72 29.3625C109.753 29.3625 110.57 29.0542 111.17 28.4375C111.77 27.8208 112.07 26.9958 112.07 25.9625V25.2875L109.22 25.5375C108.22 25.6208 107.503 25.8375 107.07 26.1875C106.637 26.5375 106.42 26.9792 106.42 27.5125C106.42 28.1292 106.62 28.5958 107.02 28.9125C107.437 29.2125 108.003 29.3625 108.72 29.3625Z"
        fill="#F4673B"
      />
      <path
        d="M116.295 24.9375C116.295 23.6708 116.554 22.5542 117.07 21.5875C117.587 20.6042 118.295 19.8375 119.195 19.2875C120.112 18.7375 121.179 18.4625 122.395 18.4625C123.995 18.4625 125.312 18.8792 126.345 19.7125C127.395 20.5458 128.012 21.6708 128.195 23.0875H125.495C125.312 22.3542 124.954 21.7958 124.42 21.4125C123.887 21.0292 123.237 20.8375 122.47 20.8375C121.787 20.8375 121.179 21.0042 120.645 21.3375C120.112 21.6708 119.695 22.1458 119.395 22.7625C119.112 23.3625 118.97 24.0875 118.97 24.9375C118.97 25.7542 119.104 26.4708 119.37 27.0875C119.654 27.7042 120.045 28.1792 120.545 28.5125C121.062 28.8458 121.67 29.0125 122.37 29.0125C123.187 29.0125 123.87 28.8208 124.42 28.4375C124.97 28.0375 125.337 27.4792 125.52 26.7625H128.22C128.07 27.6792 127.729 28.4875 127.195 29.1875C126.662 29.8875 125.979 30.4375 125.145 30.8375C124.329 31.2208 123.404 31.4125 122.37 31.4125C121.154 31.4125 120.087 31.1458 119.17 30.6125C118.254 30.0625 117.545 29.3042 117.045 28.3375C116.545 27.3708 116.295 26.2375 116.295 24.9375Z"
        fill="#F4673B"
      />
      <path
        d="M132.57 31.0875H129.92V12.5875H132.57V24.2875L137.72 18.8125H141.07L136.395 23.6875L140.995 31.0875H137.92L134.545 25.6375L132.57 27.6875V31.0875Z"
        fill="#F4673B"
      />
    </svg>
  );
};
