import { useNavigate, useParams } from "react-router-dom";

import { useShowToast } from "@/components/toast";
import { MODAL_MESSAGES } from "@/constants/alerts-messages";
import { TOAST_MESSAGES } from "@/constants/toast-constants";
import { useAppDispatch } from "@/reduxHooks";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { CATEGORY } from "@/utils/enums";

import { useDeleteProjectMutation } from "../api";
import { ProjectSchema } from "../types";

const useProjectActions = () => {
  const [delProject] = useDeleteProjectMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useShowToast();

  const deleteProject = (data: ProjectSchema) => {
    if (!data) return; //TODO handle error
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRMATION,
        modalProps: {
          ...MODAL_MESSAGES.deleteProject,
          primaryAction: () => {
            delProject({ id: data.id, wsId: data.workspaceId })
              .then(() => {
                navigate(`/home/${data.workspaceId}`);
                toast(TOAST_MESSAGES.projectDeleted(data.name));
              })
              .catch((e) => console.log(e));
          },
        },
      })
    );
  };

  const editProject = (data: ProjectSchema) => {
    if (!data) return; //TODO handle error
    dispatch(
      openModal({
        modalType: ModalTypes.PROJECT_CREATION,
        modalProps: { data: { ...data, wsId: data.workspaceId } },
      })
    );
  };

  const viewProjectInfo = (
    e: React.MouseEvent<Element, MouseEvent>,
    data: ProjectSchema
  ) => {
    e.stopPropagation();
    dispatch(
      openModal({
        modalType: ModalTypes.INFO,
        modalProps: { type: CATEGORY.Project, data: data, navigate },
      })
    );
  };

  const createNewProject = (data: ProjectSchema) => {
    dispatch(
      openModal({
        modalType: ModalTypes.PROJECT_CREATION,
        modalProps: {
          parentWsId: data.workspaceId,
        },
      })
    );
  };

  const editProjectAccess = (data: ProjectSchema) => {
    dispatch(
      openModal({
        modalType: ModalTypes.MANAGE_ACCESS,
        modalProps: { object: data, type: CATEGORY.Project, navigate },
      })
    );
  };

  const openParentWorkspace = (data: ProjectSchema) => {
    navigate(`/home/${data.workspaceId}`);
  };

  return {
    deleteProject,
    editProject,
    viewProjectInfo,
    createNewProject,
    editProjectAccess,
    openParentWorkspace,
  };
};

export default useProjectActions;
