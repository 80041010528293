import { MICRO_SERVICES } from "./enums";
const DEV_MODE_STRING = "development";

export const regionMap: Record<string, string> = {
  cus: "US Central (CUS)",
  uae: "UAE (UAE)",
};

const devBaseUrl = () => {
  return import.meta.env.VITE_DEV_BASE_URL;
};

export const getBaseUrl = () => {
  console.log(import.meta.env.MODE, DEV_MODE_STRING);
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    return devBaseUrl();
  }

  const { protocol, hostname } = window.location;
  const parts = hostname.split(".");
  let backendHostname = "";
  if (parts.length > 0) {
    if (parts.length === 5) {
      backendHostname = "backend." + parts.join(".");
    } else {
      parts[0] = "backend";
      backendHostname = parts.join(".");
    }
  }
  return `${protocol}//${backendHostname}`;
};

export const getMSBaseUrl = (serviceName: MICRO_SERVICES) => {
  const baseUrl = getBaseUrl();
  switch (serviceName) {
    case MICRO_SERVICES.NODE_MANAGER:
      return `${baseUrl}/node-mgmt`;
    case MICRO_SERVICES.WORKFLOW_MANAGER:
      return `${baseUrl}/workflow-mgmt`;
    case MICRO_SERVICES.WORKSPACE_MANAGER:
      return `${baseUrl}/workspace-mgmt`;
    case MICRO_SERVICES.DATA_MANAGER:
      return `${baseUrl}/data-mgmt`;
    case MICRO_SERVICES.PDS:
      return `${baseUrl}/pds-mgmt`;
    case MICRO_SERVICES.USER_MANAGER:
      return `${baseUrl}/user-mgmt`;
    case MICRO_SERVICES.AI_PLAYGROUND:
      return `${baseUrl}/playground-mgmt`;
    case MICRO_SERVICES.EDA:
      return `${baseUrl}/eda-mgmt`;
    case MICRO_SERVICES.INSIGHTS:
      return `${baseUrl}/insights-mgmt`;
    default:
      return `${baseUrl}`;
  }
};

/**
 * org1.uae.ctnxt.clootrack.com
 * org2.cus.ctnxt.clootrack.com
 */
export const getRegionFromUrl = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    return "CUS"; // Dev is only on cus
  }
  const { hostname } = window.location;
  const parts = hostname.split(".");
  if (parts.length === 5) {
    return parts[0];
  } else {
    return parts[1];
  }
  return "";
};

export const getRedirectUrl = () => {
  if (import.meta.env.MODE === DEV_MODE_STRING) {
    console.log("localhost redirect");
    return "http://localhost:3000";
  }
  const { protocol, hostname } = window.location;
  return `${protocol}//${hostname}/`;
};

export const getAuthClientIdFromRegion = () => {
  const region = getRegionFromUrl().toUpperCase();
  const envVariableName = `VITE_AZURE_CLIENT_ID_${region}`;
  return import.meta.env[envVariableName];
};

export const getAuthTenantFromRegion = () => {
  const region = getRegionFromUrl().toUpperCase();
  const envVariableName = `VITE_AZURE_TENANT_${region}`;
  return import.meta.env[envVariableName];
};
