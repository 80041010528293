import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { ApiResponse } from "@/types";

import {
  IUploadChunkReqPayload,
  IUploadChunkResponse,
  SourcesList,
  ValidationResponse,
} from "../types";

import { dataManagerAPI } from "./api";
import { DataManagerEndpoints } from "./api-endpoints.ts";

export const dataUploadAPI = dataManagerAPI.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    getSources: build.query<ApiResponse<SourcesList>, void>({
      query: () => {
        return {
          url: DataManagerEndpoints.getSources,
        };
      },
    }),
    uploadLocalFile: build.mutation<
      ApiResponse<any>,
      {
        formData: FormData;
        analysisId: string;
      }
    >({
      query: ({ formData, analysisId }) => {
        return {
          url: DataManagerEndpoints.uploadLocalFile(analysisId),
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["IORecords"],
    }),
    runValidations: build.mutation<
      ApiResponse<ValidationResponse>,
      { analysisId: string; validationType: string; body: any }
    >({
      query: ({ analysisId, validationType, body }) => {
        return {
          url: DataManagerEndpoints.runValidation(analysisId, validationType),
          method: "POST",
          body: body,
        };
      },
    }),
    getUploadOffset: build.query<
      IUploadChunkResponse,
      { url: string; uploadId: string }
    >({
      query: ({ url, uploadId }) => {
        return {
          url: url ?? DataManagerEndpoints.getUploadOffset(uploadId),
        };
      },
    }),
    uploadChunk: build.mutation<IUploadChunkResponse, IUploadChunkReqPayload>({
      query: ({ url, formData, startByte, endByte, totalSize }) => {
        const uploadUrl = url || DataManagerEndpoints.uploadFileChunk;
        return {
          url: uploadUrl,
          method: "PUT",
          headers: {
            "Content-Range": `bytes ${startByte}-${endByte - 1}/${totalSize}`,
          },
          timeout: 60000,
          body: formData,
        };
      },
    }),
    finishUpload: build.mutation<
      IUploadChunkResponse,
      {
        analysisId: string;
        ioRecordId: string;
        url: string;
        sheetName?: string;
      }
    >({
      query: ({ analysisId, ioRecordId, url, sheetName }) => {
        return {
          url: url,
          method: "POST",
          body: {
            io_record_id: ioRecordId,
            analysis_id: analysisId,
            excel_sheet_name: sheetName,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSourcesQuery,
  useUploadLocalFileMutation,
  useRunValidationsMutation,
  useUploadChunkMutation,
  useFinishUploadMutation,
  useLazyGetUploadOffsetQuery,
} = dataUploadAPI;
