import { Flex, Input, Menu, MenuButton, Spinner, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { Close } from "@/components/icons/close.tsx";
import { Search } from "@/components/icons/search.tsx";
import { Icon } from "@/design/components/icon";
import { MenuItem, MenuList } from "@/design/components/menu";
import {
  setPreviewAnalysis,
  useLazySearchInWsQuery,
} from "@/features/ws-manager";
import { CustomIcon } from "@/features/ws-manager/components/get-icon.tsx";
import {
  AnalysesSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";
import useElementDimensions from "@/hooks/useDimentions.tsx";
import { getRelativeDate } from "@/utils/date-convertor.ts";
import { CATEGORY } from "@/utils/enums.ts";

export const ResultsDropdown = () => {
  const { dimensions, ref } = useElementDimensions();
  const { width } = dimensions ?? {};
  const inputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setParam, query, removeCurrentParam } = useCustomQuery();
  const isGlobalSearchActive = !!query;
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(query ?? "");
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [triggerSearch, { data, isFetching }] = useLazySearchInWsQuery();

  const searchResults = isEmpty(value)
    ? []
    : data?.response?.data?.results.slice(0, 5) ?? [];
  const isEmptyResults =
    !isFetching && (!searchResults || searchResults?.length === 0);
  const showResults = !isEmptyResults && !isFetching;

  const showFocusedStyle = isFocused || isGlobalSearchActive || isMenuOpen;

  useEffect(() => {
    if (!query) setValue("");
    else setValue(query);
  }, [query]);

  const onClick = (
    item: WorkspaceSchema | AnalysesSchema | ProjectSchema,
    type: CATEGORY
  ) => {
    ref.current?.focus();
    setValue("");
    switch (type) {
      case CATEGORY.Project:
        // eslint-disable-next-line no-case-declarations
        const project = item as ProjectSchema;
        navigate(`/home/${project.workspaceId}/project/${project.id}`);
        break;
      case CATEGORY.Workspace:
        // eslint-disable-next-line no-case-declarations
        const ws = item as WorkspaceSchema;
        navigate(`/home/${ws.id}`);
        break;
      case CATEGORY.Analysis:
        // eslint-disable-next-line no-case-declarations
        const analysis = item as Partial<AnalysesSchema>;
        dispatch(setPreviewAnalysis(null));
        navigate(
          `/home/${analysis.workspaceId}/project/${analysis.projectId}?analysis=${analysis.id}`
        );
        break;
      default:
        break;
    }
  };

  const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    focusInput();
  };

  const onFocused = () => {
    setIsFocused(true);
    setMenuOpen(true);
    focusInput();
  };
  const onBlurred = () => {
    setIsFocused(false);
  };

  const focusInput = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const [debouncedSearchTerm] = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedSearchTerm.trim().length > 0) {
      searchApi();
    }
  }, [debouncedSearchTerm]);

  const onClearSearch = () => {
    setValue("");
    removeCurrentParam();
    setMenuOpen(false);
    
    const currentPath = window.location.pathname;
    navigate(currentPath);
  };

  const searchApi = async () => {
    await triggerSearch({ search: debouncedSearchTerm, isSearchPage: false });
    setMenuOpen(true);
    focusInput();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const val = value.trim() ?? debouncedSearchTerm.trim();
      if (val.length > 0) {
       setParam(val);
      } else {
        setValue("");
      }
      inputRef.current?.blur();
      ref.current?.focus();
      setMenuOpen(false);
    }
  };

  const handleScroll = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleTableScroll = () => {
      setMenuOpen(false);
    };

    window.addEventListener('tableScroll', handleTableScroll);
    return () => {
      window.removeEventListener('tableScroll', handleTableScroll);
    };
  }, []);

  return (
    <>
      <Flex
        className={clsx(
          "flex-row items-center gap-x-2 p-3 max-w-2xl w-full",
          showFocusedStyle && "border border-gray-300 rounded-lg bg-gray-50",
          isMenuOpen && "rounded-b-none !border-b-0"
        )}
        ref={ref}
      >
        <Icon
          as={Search}
          color={showFocusedStyle ? "gray.900" : "gray.600"}
          size="lg"
        />
        <Input
          className=""
          ref={inputRef}
          onBlur={onBlurred}
          onChange={onSearchTermChange}
          onClick={onFocused}
          onFocus={onFocused}
          onKeyUp={handleKeyPress}
          placeholder="Search in all workspaces"
          value={value}
          variant="unstyled"
        />
        {value.length > 0 && (
          <Icon
            as={Close}
            color="gray.900"
            size="lg"
            className="cursor-pointer"
            onClick={onClearSearch}
          />
        )}
      </Flex>
      <Menu
        isOpen={isMenuOpen}
        onClose={setMenuOpen.bind(null, false)}
        onOpen={setMenuOpen.bind(null, true)}
      >
        <MenuButton className={clsx("absolute p-3")} as={Flex}></MenuButton>
        <MenuList
          className="flex-row items-center gap-x-2 px-0 p-3 !max-w-2xl mt-[4px] !rounded-t-none"
          w={width}
          onScroll={handleScroll}
        >
          {isFetching && (
            <Flex className="justify-center items-center min-h-28 h-[calc(100vh/4)]">
              <Spinner size="sm" />
            </Flex>
          )}
          {isEmptyResults && (
            <Flex className="justify-center text-lg italic font-normal items-center text-gray-500 min-h-28 h-[calc(100vh/4)]">
              {isEmpty(value) ? "Search Workspace" : "No Results"}
            </Flex>
          )}
          {showResults &&
            searchResults.map(({ itemData, itemType }, idx) => (
              <MenuItem
                key={itemData.id + idx}
                className="flex flex-row w-full max-w-2xl"
                onClick={onClick.bind(null, itemData, itemType)}
              >
                <Flex className="flex flex-row w-full p-3 justify-between items-center gap-x-2">
                  <Flex className="flex-row items-center gap-x-2">
                    <CustomIcon type={itemType} color="purple.500" />
                    <Text className="text-[15px] font-medium">
                      {itemData.name}
                    </Text>
                  </Flex>
                  <Text className="text-xs font-medium text-gray-700">
                    {getRelativeDate(itemData?.createdOn)}
                  </Text>
                </Flex>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </>
  );
};
