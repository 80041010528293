import { Avatar, Box, Button, Flex, Spinner } from "@chakra-ui/react";
import { isEmpty, orderBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiFillFolder, AiOutlineInfoCircle } from "react-icons/ai";
import {
  MdOutlineEdit,
  MdOutlineGroup,
  MdOutlineNoteAdd,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdsErrorRound } from "react-icons-with-materialsymbols/mds";
import { Link, useNavigate, useParams } from "react-router-dom";

import { LogoWithText } from "@/components/icons/logo-text.tsx";
import { Logo } from "@/components/icons/logo.tsx";
import { Icon } from "@/design/components/icon";
import {
  NavigationPanel,
  NavigationPanelFooter,
  NavigationPanelItem,
  NavigationPanelSubItem,
  NavigationPanelBody,
  NavigationLabelHeading,
  NavigationPanelHeader,
} from "@/design/components/navigation-panel";
import {
  AnalysesSchema,
  ProjectSchema,
  WorkspaceSchema,
} from "@/features/ws-manager/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice.ts";
import { showContextMenu } from "@/slices/context-menu-slice.ts";
import { CATEGORY, PERMISSIONS, USER_ROLES } from "@/utils/enums.ts";

import { useGetAnalysisFavouriteQuery, useLazyGetWsListQuery } from "../../api";
import useProjectActions from "../../hooks/useProject.ts";
import useWorkspaceActions from "../../hooks/useWorkspace.ts";
import {
  setCurrentProject,
  setCurrentWs,
  setPreviewAnalysis,
} from "../../index.ts";

import { HeaderButtons } from "./header-buttons.tsx";

export const MainSideNav = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [
    getWorkspaces,
    { data: wsData, isFetching, isSuccess, isLoading, isError },
  ] = useLazyGetWsListQuery();
  const { data: favoritesData } = useGetAnalysisFavouriteQuery();
  const workspaces = wsData?.response.data?.workspaces ?? [];
  const favorites = favoritesData?.response.data?.favorites?.analyses ?? [];
  const dispatch = useAppDispatch();
  const loadingRef = useRef<HTMLDivElement | null>(null);

  const userData = useAppSelector(currentUserMetadata);
  const showCreateNewButton = userData?.role !== USER_ROLES.CTK_ADMIN;

  const {
    deleteWorkspace,
    editWorkspace,
    viewWorkspaceInfo,
    editWorkspaceAccess,
    createNewWorkspace,
  } = useWorkspaceActions();

  const {
    deleteProject,
    editProject,
    viewProjectInfo,
    editProjectAccess,
    createNewProject,
  } = useProjectActions();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const fetchMoreWorkSpaces = async () => {
      const nextUrl: string | null | undefined =
        wsData?.response.pagination?.next;
      if (isError) return;

      if (nextUrl && !isFetching) {
        await getWorkspaces(nextUrl);
      } else if (isEmpty(wsData)) {
        await getWorkspaces();
      }
    };

    const target = loadingRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) fetchMoreWorkSpaces();
      },
      { threshold: 1.0 }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [isFetching, wsData, getWorkspaces, isError]);

  const navigateToWorkspace = (value: Partial<WorkspaceSchema>) => {
    navigate(`${value.id}`);
    dispatch(setCurrentWs(value as WorkspaceSchema));
    if (routeParams?.workspace !== value.id) {
      dispatch(setPreviewAnalysis(null));
    }
  };
  const navigateToProject = (value: Partial<ProjectSchema>) => {
    navigate(`/home/${value.workspaceId}/project/${value.id}`);

    dispatch(setCurrentProject(value as ProjectSchema));
    if (routeParams?.project !== value.id) {
      dispatch(setPreviewAnalysis(null));
    }
  };

  const onFavouriteClick = (analysis: Partial<AnalysesSchema>) => {
    dispatch(setPreviewAnalysis(analysis));
  };

  const isEmptyWorkspace = workspaces.length === 0 && !isError && !isLoading;

  const sortedWS = orderBy(workspaces, [(ws) => ws.name!.toLowerCase()], "asc");

  const sortedProjects = (
    projects: Partial<ProjectSchema>[] | null | undefined
  ) => {
    if (isEmpty(projects)) return [];
    return orderBy(projects, [(p) => p.name!.toLowerCase()], "asc");
  };

  const handleContextMenu = useCallback(
    (
      e: React.MouseEvent,
      type: CATEGORY,
      rowData: Partial<WorkspaceSchema> | Partial<ProjectSchema>
    ) => {
      const menuitems = [
        {
          label: "View " + type + " Info",
          action: () =>
            type === CATEGORY.Workspace
              ? viewWorkspaceInfo(e, rowData as WorkspaceSchema)
              : viewProjectInfo(e, rowData as ProjectSchema),
          icon: AiOutlineInfoCircle,
          isProtected: true,
          extraOptions: {
            permission: PERMISSIONS.READ,
            data: {
              id: rowData.id,
              type: type,
            },
          },
        },
        {
          label: "Edit " + type + " Details",
          action: () =>
            type === CATEGORY.Workspace
              ? editWorkspace(rowData as WorkspaceSchema)
              : editProject(rowData as ProjectSchema),
          icon: MdOutlineEdit,
          isProtected: true,
          extraOptions: {
            permission: PERMISSIONS.WRITE,
            data: {
              id: rowData.id,
              type: type,
            },
          },
        },
        {
          label: "Manage Access",
          action: () =>
            type === CATEGORY.Workspace
              ? editWorkspaceAccess(rowData as WorkspaceSchema)
              : editProjectAccess(rowData as ProjectSchema),
          icon: MdOutlineGroup,
          isProtected: true,
          extraOptions: {
            permission: PERMISSIONS.WRITE,
            data: {
              id: rowData.id,
              type: type,
            },
          },
        },
        {
          label: "Delete " + type,
          action: () =>
            type === CATEGORY.Workspace
              ? deleteWorkspace(rowData as WorkspaceSchema)
              : deleteProject(rowData as ProjectSchema),
          icon: RiDeleteBin6Line,
          isProtected: true,
          extraOptions: {
            permission: PERMISSIONS.DELETE,
            data: {
              id: rowData.id,
              type: type,
            },
          },
          color: "red.600",
        },
      ];

      if (showCreateNewButton) {
        menuitems.unshift({
          label: "Create New " + type,
          action: () =>
            type === CATEGORY.Workspace
              ? createNewWorkspace()
              : createNewProject(rowData as ProjectSchema),
          icon: MdOutlineNoteAdd,
          isProtected: true,
          extraOptions: {
            permission: PERMISSIONS.CREATE,
            data: {
              id: rowData.id,
              type: type,
            },
          },
        });
      }

      e.preventDefault();
      dispatch(
        showContextMenu({
          x: e.clientX,
          y: e.clientY,
          items: menuitems,
        })
      );
    },
    [dispatch, showCreateNewButton]
  );

  return (
    <NavigationPanel
      type="manager"
      index={tabIndex}
      onChange={handleTabsChange}
      minW="17.5rem"
    >
      <NavigationPanelHeader logo={<Logo />}>
        <Link to={"/home"}>
          <LogoWithText />
        </Link>
      </NavigationPanelHeader>
      <HeaderButtons setIndex={handleTabsChange} />
      <NavigationPanelBody className="relative">
        {!isEmpty(favorites) && <NavigationLabelHeading label="Favorites" />}
        {favorites.map((favorite: Partial<AnalysesSchema>, idx: number) => (
          <NavigationPanelItem
            key={idx}
            icon={<Icon as={AiFillFolder} />}
            label={favorite.name}
            onClick={onFavouriteClick.bind(null, favorite)}
            highlightOnSelect={false}
          />
        ))}
        <NavigationLabelHeading label="Your Workspaces" />
        <Box>
          {!isLoading &&
            isSuccess &&
            sortedWS.map((ws: Partial<WorkspaceSchema>) => (
              <NavigationPanelItem
                onContextMenu={(e) =>
                  handleContextMenu(e, CATEGORY.Workspace, ws)
                }
                className="capitalize"
                highlight={
                  ws.id === routeParams.workspace && !routeParams.project
                }
                selected={routeParams.workspace === ws.id}
                key={ws.id}
                icon={
                  ws.emoji ? (
                    <span>{ws.emoji}</span>
                  ) : (
                    <Avatar
                      bgColor={ws.icon ?? "brand.300"}
                      name={ws.name}
                      size={"xs"}
                    />
                  )
                }
                label={ws.name}
                onClick={navigateToWorkspace.bind(null, ws)}
              >
                {sortedProjects(ws.projects).map((project) => (
                  <NavigationPanelSubItem
                    onContextMenu={(e) =>
                      handleContextMenu(e, CATEGORY.Project, project)
                    }
                    className="capitalize"
                    selected={routeParams.project === project.id}
                    key={project.id}
                    onClick={navigateToProject.bind(null, project)}
                    icon={<Icon as={AiFillFolder} />}
                  >
                    {project.name}
                  </NavigationPanelSubItem>
                ))}
              </NavigationPanelItem>
            ))}
          {isEmptyWorkspace && (
            <Flex className="justify-center text-gray-600 items-center gap-3">
              <span>No Workspaces Found</span>
            </Flex>
          )}
          {isError && (
            <Flex className="justify-center text-white items-center gap-2">
              <MdsErrorRound className="text-gray-600" />
              <span className="text-sm text-gray-600">
                Error Loading Workspaces
              </span>
            </Flex>
          )}
          <div ref={loadingRef}>
            {isFetching && (
              <Flex className="justify-center text-gray-600 items-center gap-3">
                <Spinner size={"xs"} />
                <span>Loading</span>
              </Flex>
            )}
          </div>
        </Box>
      </NavigationPanelBody>
      {/*TODO: DISABLE THIS MENU ITEM*/}
      {/*  <NavigationPanelFooter>
        <NavigationLabelHeading label="Utilities" />
       <Button className="!justify-start" colorScheme="invert" variant="ghost">
          Run Monitor
        </Button> 
        <Button
          className="!justify-start"
          colorScheme="invert"
          onClick={() => {
            navigate("recycle-bin");
          }}
          variant="ghost"
        >
          Recycle Bin
        </Button> 
         <Button className="!justify-start" colorScheme="invert" variant="ghost">
          Knowledge Center
        </Button> 
      </NavigationPanelFooter>
      */}
    </NavigationPanel>
  );
};
