import { useMsal } from "@azure/msal-react";
import { Flex, Portal, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdOutlineManageAccounts } from "react-icons/md";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import { MdsLogoutRound } from "react-icons-with-materialsymbols/mds";
import { useNavigate, useParams } from "react-router-dom";

import { AccountCircleFilled } from "@/components/icons/avatar-filled.tsx";
import { Avatar } from "@/design/components/avatar";
import { Icon } from "@/design/components/icon";
import { Menu, MenuButton, MenuItem, MenuList } from "@/design/components/menu";
import { getOnGoingAnalysisUploads } from "@/features/data-manager/redux";
import { selectActiveDownloads } from "@/features/workflow-studio/redux";
import { useAppSelector } from "@/reduxHooks";
import { CacheKey, cacheUtil } from "@/utils/localstorage";

import { OrgDropdown } from "./org-dropdown";

export const UserDropdown = () => {
  const params = useParams();
  const [userName, setUsername] = useState("User Name");
  const { instance } = useMsal();
  const navigate = useNavigate();
  const account = instance.getActiveAccount();

  const activeDownloads = useAppSelector(selectActiveDownloads);

  const uploads = useAppSelector((s) =>
    getOnGoingAnalysisUploads(s, params.analysisId!)
  );

  useEffect(() => {
    if (account) {
      const { idTokenClaims } = account;
      setUsername(idTokenClaims!.name!);
    }
  }, [account]);

  const allowNavigate = () => {
    if (uploads && uploads.length > 0) {
      const userInput = confirm(
        "Upload is in progress. Do you want to navigate away?"
      );
      if (!userInput) return false;
    } else if (activeDownloads && activeDownloads.length > 0) {
      const userInput = confirm(
        "Alert! Exiting this page will cancel your download.\nAre you sure you want to proceed?"
      );
      if (!userInput) return false;
    }
    return true;
  };
  const openProfileSettings = () => {
    if (allowNavigate()) {
      navigate(`/settings/user/me/account-details`);
    }
  };

  const handleSignout = () => {
    if (!allowNavigate()) return;
    if (!account) return;
    cacheUtil.remove(CacheKey.TenantId);
    cacheUtil.remove(CacheKey.UserId);
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "https://cus.dev.ctnxt.clootrack.com/logout",
      })
      .catch((e) => console.log(e));
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton className="hover:!bg-gray-100">
            <Flex
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
                padding: "1rem 1.25rem",
              }}
            >
              <Avatar
                size="xs"
                icon={<AccountCircleFilled />}
                fontSize={24}
                bg="transparent"
                color={"gray.900"}
                p={0}
              >
                {/* <AvatarBadge bg="green.500" /> */}
              </Avatar>
              <Text
                sx={{
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "175%",
                  letterSpacing: "-0.02rem",
                  color: "gray.900",
                }}
              >
                {userName}
              </Text>
              <Icon
                as={isOpen ? TbChevronUp : TbChevronDown}
                height="1.5rem"
                width="1.5rem"
                sx={{
                  marginLeft: "0.75rem",
                  stroke: "gray.900",
                }}
              />
            </Flex>
          </MenuButton>
          <Portal>
            <MenuList className="mr-4 -mt-2 z-10">
              <MenuItem
                icon={<Icon as={MdOutlineManageAccounts} />}
                onClick={openProfileSettings}
                // isDisabled={true}
              >
                Settings
              </MenuItem>
              {/*
            <MenuItem
              icon={<Icon as={MdOutlineMonitorHeart} />}
              isDisabled={true}
            >
              Run Monitor
            </MenuItem>
            <MenuItem icon={<Icon as={AiOutlineBook} />} isDisabled={true}>
              Knowledge Centre
            </MenuItem>
            <Divider />
            <Divider />
            */}
              <MenuItem
                onClick={handleSignout}
                icon={
                  <Icon as={MdsLogoutRound} fontSize={24} strokeWidth={22} />
                }
              >
                Sign Out
              </MenuItem>
              <OrgDropdown />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};
