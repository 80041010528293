import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import { Analysis } from "@/features/ws-manager/components/analysis";
import { NavBar } from "@/features/ws-manager/components/nav-bar/nav-bar.tsx";
import { WorkspaceSearch } from "@/features/ws-manager/components/workspace-search";
import { useCustomQuery } from "@/hooks/useCustomQuery.ts";

const MotionBox = motion(Box);

export const Home = () => {
  const { query } = useCustomQuery();
  const isGlobalSearchActive = !!query;

  return (
    <Flex className="w-full flex-col h-full overflow-hidden" bg="gray.100">
      <NavBar />
      <Box className="grow bg-black overflow-hidden relative">
        <AnimatePresence mode="wait">
          {isGlobalSearchActive ? (
            <MotionBox
              key="search"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="absolute inset-0"
            >
              <WorkspaceSearch />
            </MotionBox>
          ) : (
            <MotionBox
              key="analysis"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="absolute inset-0"
            >
              <Analysis />
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </Flex>
  );
};
