import { EndpointBuilder } from "@reduxjs/toolkit/query";

import { userApi } from "@/features/user-manager";
import {
  SearchResultsSchema,
  WorkspaceCreateSchema,
  WorkspaceListSchema,
} from "@/features/ws-manager/types";
import { ApiResponse } from "@/types";
import { ACTION_TYPE } from "@/utils/enums.ts";

import { wsManagerApi } from "./api";
import { WsManagerEndpoints } from "./api-endpoints";
import { addWorkspace, removeWorkspace, updateWorkspace } from "./ws-api-utils";

export const workspaceAPI = wsManagerApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => ({
    createWs: build.mutation<
      ApiResponse<WorkspaceListSchema>,
      WorkspaceCreateSchema
    >({
      query: (body) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;

        return {
          url: WsManagerEndpoints.createWs(clientId),
          method: "POST",
          data: body,
        };
      },
      // TODO : Updating local cache here because of pagination issue, need to fix this to updating using invalidatesTags
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        const patchResult = dispatch(
          workspaceAPI.util.upsertQueryData("getWsList", undefined, res)
        );
        dispatch(
          userApi.util.invalidateTags([
            { type: "WorkspaceAccessList", id: "project" },
            { type: "WorkspaceAccessList", id: "analysis" },
          ])
        );
      },
    }),
    updateWs: build.mutation<
      ApiResponse<WorkspaceListSchema>,
      Partial<WorkspaceCreateSchema & { action: ACTION_TYPE }> & { id: string }
    >({
      query: ({ id, ...body }) => {
        return {
          url: WsManagerEndpoints.workspace(id),
          method: "PUT",
          data: body,
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        updateWorkspace(dispatch, res);
      },
      invalidatesTags: (result) => [
        { type: "SearchInWs", id: result?.response?.data?.workspaces?.[0]?.id },
        { type: "SearchInWs", id: "LIST" }
      ],
      // invalidatesTags: (_apiRes, _error, data) => [
      //   { type: "Workspaces", id: data.id },
      //   { type: "Workspaces" as const, id: "LIST" },
      // ],
    }),
    deleteWs: build.mutation<ApiResponse<WorkspaceListSchema>, string>({
      query(id) {
        return {
          url: WsManagerEndpoints.workspace(id),
          method: "DELETE",
        };
      },
      // TODO : Updating local cache here because of pagination issue, need to fix this to updating using invalidatesTags
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data: res } = await queryFulfilled;
        removeWorkspace(dispatch, body);
      },
      invalidatesTags: (_apiRes, _error, id) => [
        { type: "SearchInWs", id: id },
        { type: "SearchInWs", id: "LIST" },
      ],
    }),
    getWs: build.query<ApiResponse<WorkspaceListSchema>, string>({
      query: (id) => {
        return {
          url: WsManagerEndpoints.workspace(id),
          params: {
            exclude: "analysis",
          },
        };
      },
      providesTags: (result = { response: { data: null } }) => [
        ...(result.response.data?.workspaces ?? []).map(
          ({ id }) => ({ type: "Workspaces", id }) as const
        ),
      ],
    }),
    searchInWs: build.query<
      ApiResponse<SearchResultsSchema>,
      { search: string; url?: string | null; isSearchPage?: boolean }
    >({
      query: ({ search, url, isSearchPage = true }) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;
        return {
          url: url ? url : WsManagerEndpoints.searchInWs(clientId),
          params: {
            query: search,
            filter: "all",
          },
        };
      },
      merge: (existingData, newData, req) => {
        const isSearchPage =
          req.arg.isSearchPage !== null ? req.arg.isSearchPage : true;
        if (isSearchPage) return newData;
        if (!existingData || req.arg.url === null) {
          return newData;
        }

        // Create a Map using composite key of type+id for uniqueness
        const resultsMap = new Map();
        
        // Add existing results to map
        existingData.response.data?.results?.forEach(item => {
          const key = `${item.itemType}_${item.itemData.id}`;
          resultsMap.set(key, item);
        });
        
        // Update/add new results, replacing any existing ones
        newData.response.data?.results?.forEach(item => {
          const key = `${item.itemType}_${item.itemData.id}`;
          resultsMap.set(key, item);
        });

        return {
          ...newData,
          response: {
            ...newData.response,
            data: {
              results: Array.from(resultsMap.values()),
            },
            pagination: {
              count: newData.response.pagination?.count ?? 0,
              next: newData.response.pagination?.next ?? null,
              previous: newData.response.pagination?.previous ?? null,
            },
          },
        };
      },
      providesTags: (result) => [
        { type: "SearchInWs", id: "LIST" },
        ...(result?.response?.data?.results ?? []).map((item) => ({
          type: "SearchInWs",
          id: item.itemData.id,
        })),
      ],
      keepUnusedDataFor: 30,
      serializeQueryArgs: ({ queryArgs }) => `SearchInWs${queryArgs.search}`,
    }),
    getWsList: build.query<ApiResponse<WorkspaceListSchema>, string | void>({
      query: (url) => {
        const clientId = import.meta.env.VITE_CLIENT_ID;
        return {
          url: url ? url : WsManagerEndpoints.getWsList(clientId),
          params: {
            exclude: "analysis",
            page_size: 25,
          },
        };
      },
      providesTags: (result = { response: { data: null } }) => [
        ...(result.response.data?.workspaces ?? []).map(
          ({ id }) => ({ type: "Workspaces", id }) as const
        ),
        { type: "Workspaces" as const, id: "LIST" },
      ],
      merge: (existingData, newData, req) => {
        if (!existingData || req.arg === null) {
          return newData;
        }
        const existingWorkspaces =
          existingData?.response.data?.workspaces ?? [];
        const newWorkspaces = newData.response.data?.workspaces ?? [];

        // Create a map of existing workspaces with their ids as the key for constant time lookup
        const workspaceMap = new Map(
          existingWorkspaces.map((workspace) => [workspace.id, workspace])
        );

        // Iterate through newWorkspaces, updating the map (this will either update an existing workspace, or add a new one)
        newWorkspaces.forEach((workspace) => {
          workspaceMap.set(workspace.id, workspace);
        });

        const updatedWorkspaces = Array.from(workspaceMap.values());

        return {
          ...newData,
          response: {
            ...newData.response,
            data: {
              workspaces: updatedWorkspaces,
            },
            pagination: {
              count: newData.response.pagination?.count ?? 0,
              next: newData.response.pagination?.next ?? null,
              previous: newData.response.pagination?.previous ?? null,
            },
          },
        };
      },
      serializeQueryArgs: () => "WorkspacesList",
    }),
    getWsItemsByIdList: build.mutation<
      ApiResponse<WorkspaceListSchema>,
      {
        idList: string[];
        entityType: string;
      }
    >({
      query: ({ entityType, idList }) => {
        return {
          url: WsManagerEndpoints.getWsItemsByIdList(),
          method: "POST",
          params: {
            exclude: "analysis",
          },
          body: {
            item_type: entityType,
            id_list: idList,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateWsMutation,
  useDeleteWsMutation,
  useGetWsListQuery,
  useLazyGetWsListQuery,
  useUpdateWsMutation,
  useLazyGetWsQuery,
  useLazySearchInWsQuery,
  useGetWsItemsByIdListMutation,
  useSearchInWsQuery,
} = workspaceAPI;
