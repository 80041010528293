import { Grid, Spinner } from "@chakra-ui/react";
import { MdClose, MdSchedule } from "react-icons/md";
import {
  MdsCheckRound,
  MdsPriorityHighRound,
} from "react-icons-with-materialsymbols/mds";

import { Loader } from "@/components/icons/loader";
import { Icon } from "@/design/components/icon";
import { DATA_UPLOAD_STATUS, EDA_STATE } from "@/utils/enums.ts";

export const getDatasetIconColor = (type: string) => {
  switch (type) {
    case "output":
      return "text-brand-500 bg-brand-50";
    case "input":
      return "text-green-500 bg-green-50";
    default:
      return "text-gray-800 bg-gray-50";
  }
};

export const getIcon = (type: DATA_UPLOAD_STATUS | EDA_STATE) => {
  const icon = () => {
    switch (type) {
      case DATA_UPLOAD_STATUS.UPLOADING_TO_BRONZE_ZONE:
      case DATA_UPLOAD_STATUS.UPLOADING_TO_CS_ZONE:
        return <Spinner color="white" size="xs" />;
      case DATA_UPLOAD_STATUS.NOT_STARTED:
        return <Icon fontSize={24} as={MdSchedule} zIndex={0} color="white" />;
      case DATA_UPLOAD_STATUS.FAIL:
      case EDA_STATE.FAILED:
        return <Icon size="xs" as={MdClose} zIndex={0} color="white" />;
      case DATA_UPLOAD_STATUS.REVIEW_PENDING:
      case DATA_UPLOAD_STATUS.SELECTION_PENDING:
      case EDA_STATE.PENDING:
        return (
          <Icon
            size="xs"
            as={MdsPriorityHighRound}
            strokeWidth={22}
            zIndex={0}
            color="white"
          />
        );
      case EDA_STATE.IN_PROGRESS:
        return (
          // <Icon
          //   size="xs"
          //   as={}
          //   strokeWidth={22}
          //   zIndex={0}
          //   color="white"
          // />
          <Loader className="h-full w-full animate-spin" />
        );
      case EDA_STATE.NOT_AVAILABLE:
      case EDA_STATE.COMPLETE:
        return (
          <Icon
            size="xs"
            as={MdsCheckRound}
            strokeWidth={22}
            zIndex={0}
            color="white"
          />
        );
    }
  };

  const color = () => {
    switch (type) {
      case DATA_UPLOAD_STATUS.UPLOADING_TO_BRONZE_ZONE:
      case DATA_UPLOAD_STATUS.UPLOADING_TO_CS_ZONE:
        return "bg-blue-500";
      case DATA_UPLOAD_STATUS.NOT_STARTED:
        return "bg-orange-400";
      case DATA_UPLOAD_STATUS.FAIL:
      case EDA_STATE.FAILED:
        return "bg-red-600";
      case DATA_UPLOAD_STATUS.REVIEW_PENDING:
      case DATA_UPLOAD_STATUS.SELECTION_PENDING:
      case EDA_STATE.PENDING:
        return "bg-yellow-500";
      case EDA_STATE.COMPLETE:
      case EDA_STATE.NOT_AVAILABLE:
        return "bg-green-500";
      case EDA_STATE.IN_PROGRESS:
        return "text-gray-800";
    }
  };

  return (
    <Grid className={`rounded-full p-0.5 place-items-center ${color()}`}>
      {icon()}
    </Grid>
  );
};
