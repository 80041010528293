import { createApi } from "@reduxjs/toolkit/query/react";
import { isEmpty } from "lodash";

import {
  createStandaloneToast,
  TOAST_DURATION,
  ToastType,
} from "@/components/toast";
import { baseQuery } from "@/utils/baseQuery.ts";
import { getMSBaseUrl } from "@/utils/baseUrls.ts";
import { MICRO_SERVICES } from "@/utils/enums.ts";
import { CacheKey, cacheUtil } from "@/utils/localstorage";

export const userApi = createApi({
  reducerPath: "userManagerApi",
  baseQuery: baseQuery({
    baseUrl: getMSBaseUrl(MICRO_SERVICES.USER_MANAGER),
    defaultHeaders: {
      "X-CLIENT-ID": import.meta.env.VITE_CLIENT_ID,
    },
    errorHandlerFn: (error) => {
      const toast = createStandaloneToast();
      if (isEmpty(cacheUtil.getUnParsed(CacheKey.UserId))) {
        return;
      }
      const status = error.response?.status;
      let errorMessage;
      if (status === 401 || status === 403) {
        errorMessage = "Unauthorized action.";
      } else {
        let errMsg = "Something went wrong.";
        const defaultError =
          error.response.data?.message ?? "Oops! Something went wrong.";
        console.log(error?.response?.data?.response?.data?.error);
        if (!isEmpty(error?.response?.data?.response?.data?.error)) {
          errMsg = error?.response?.data?.response?.data?.error;
        } else if (
          !isEmpty(error.response.data?.response?.errors?.[0]?.detail)
        ) {
          errMsg = error.response.data?.response?.errors?.[0]?.detail;
        } else if (!isEmpty(error.response.data?.errors?.[0]?.detail)) {
          errMsg = error.response.data?.errors?.[0]?.detail;
        }
        errorMessage = errMsg ? `${errMsg}` : defaultError;
      }
      toast({
        title: errorMessage,
        status: ToastType.Error,
        duration: TOAST_DURATION.LONG,
        variant: "left-accent",
      });
    },
  }),
  tagTypes: ["Users", "WorkspaceAccessList", "ProjectAccessList"],
  endpoints: () => ({}),
});
