import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoShareSocialOutline } from "react-icons/io5";
import {
  MdArrowOutward,
  MdOutlineEdit,
  MdOutlineGroup,
  MdOutlineNoteAdd,
  MdStoreMallDirectory,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import { FolderOpen } from "@/components/icons/folder-open";
import { useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { CATEGORY, PERMISSIONS, USER_ROLES } from "@/utils/enums";

import { AnalysesSchema, ProjectSchema, WorkspaceSchema } from "../types";

import useAnalysis from "./useAnalysis";
import useProjectActions from "./useProject";
import useWorkspaceActions from "./useWorkspace";
type CommonItemsType = WorkspaceSchema | AnalysesSchema | ProjectSchema;

const useSearchActions = () => {
  const userData = useAppSelector(currentUserMetadata);

  const { onDelete, onEdit, openManageAccessModal, openDM, openParentProject } =
    useAnalysis();

  const {
    deleteWorkspace,
    editWorkspace,
    viewWorkspaceInfo,
    editWorkspaceAccess,
    createNewWorkspace,
  } = useWorkspaceActions();

  const {
    deleteProject,
    editProject,
    viewProjectInfo,
    editProjectAccess,
    createNewProject,
    openParentWorkspace,
  } = useProjectActions();

  const getMenuItems = (
    e: React.MouseEvent<Element, MouseEvent>,
    type: CATEGORY,
    item: CommonItemsType
  ) => {
    switch (type) {
      case CATEGORY.Workspace: {
        const isAdmin = userData?.role !== USER_ROLES.CTK_ADMIN;
        const menuItems = [
          {
            label: "View " + type + " Info",
            action: () => viewWorkspaceInfo(e, item as WorkspaceSchema),
            icon: AiOutlineInfoCircle,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.READ,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Edit " + type + " Details",
            action: () => editWorkspace(item as WorkspaceSchema),
            icon: MdOutlineEdit,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.WRITE,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Manage Access",
            action: () => editWorkspaceAccess(item as WorkspaceSchema),
            icon: MdOutlineGroup,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.WRITE,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Delete " + type,
            action: () => deleteWorkspace(item as WorkspaceSchema),
            icon: RiDeleteBin6Line,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.DELETE,
              data: {
                id: item.id,
                type: type,
              },
            },
            color: "red.600",
          },
        ];
        if (isAdmin) {
          menuItems.unshift({
            label: "Create New " + type,
            action: () => createNewWorkspace(),
            icon: MdOutlineNoteAdd,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.CREATE,
              data: {
                id: item.id,
                type: type,
              },
            },
          });
        }
        return menuItems;
        break;
      }
      default:
      case CATEGORY.Analysis: {
        return [
          {
            label: "Edit In Studio",
            action: () => openDM(e, { data: item as AnalysesSchema }),
            icon: MdArrowOutward,
          },
          {
            label: "Manage Sharing",
            action: () =>
              openManageAccessModal(e, {
                data: item as AnalysesSchema,
                type: CATEGORY.Analysis,
              }),
            icon: IoShareSocialOutline,
          },
          {
            label: "Edit Analysis Details",
            action: () => onEdit(e, { data: item as AnalysesSchema }),
            icon: MdOutlineEdit,
          },
          {
            label: "Open Project",
            action: () => openParentProject(item as AnalysesSchema),
            icon: FolderOpen,
          },
          {
            label: "Delete Analysis",
            action: () => onDelete(e, { data: item as AnalysesSchema }),
            color: "red.600",
            icon: RiDeleteBin6Line,
          },
        ];
      }
      case CATEGORY.Project: {
        return [
          //   {
          //     label: "Create New " + type,
          //     action: () => createNewProject(item as ProjectSchema),
          //     icon: MdOutlineNoteAdd,
          //     isProtected: true,
          //     extraOptions: {
          //       permission: PERMISSIONS.CREATE,
          //       data: {
          //         id: item.id,
          //         type: type,
          //       },
          //     },
          //   },
          {
            label: "View " + type + " Info",
            action: () => viewProjectInfo(e, item as ProjectSchema),
            icon: AiOutlineInfoCircle,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.READ,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Edit " + type + " Details",
            action: () => editProject(item as ProjectSchema),
            icon: MdOutlineEdit,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.WRITE,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Manage Access",
            action: () => editProjectAccess(item as ProjectSchema),
            icon: MdOutlineGroup,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.WRITE,
              data: {
                id: item.id,
                type: type,
              },
            },
          },
          {
            label: "Open Workspace",
            action: () => openParentWorkspace(item as ProjectSchema),
            icon: MdStoreMallDirectory,
          },
          {
            label: "Delete " + type,
            action: () => deleteProject(item as ProjectSchema),
            icon: RiDeleteBin6Line,
            isProtected: true,
            extraOptions: {
              permission: PERMISSIONS.DELETE,
              data: {
                id: item.id,
                type: type,
              },
            },
            color: "red.600",
          },
        ];
      }
    }
  };

  return { getMenuItems };
};

export default useSearchActions;
