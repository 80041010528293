import { Button, Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ToastType, useShowToast } from "@/components/toast";
import {
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
} from "@/design/components/modal";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { closeModal } from "@/slices/modal-slice.ts";

import { useDeleteIoRecordMutation } from "../api";
import { IoRecord } from "../types";

const CancelUploadModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useShowToast(undefined, undefined, true);
  const { dataset, onClose: onModalClose } = useAppSelector(
    (state) => state.rootReducer.modals.modalProps
  );
  const [deleteIoRecord, { isLoading: isCancelling }] =
    useDeleteIoRecordMutation();

  const datasetName = useMemo(() => {
    return (dataset as IoRecord).name;
  }, [dataset]);

  const onClose = () => {
    if (isCancelling) return;
    dispatch(closeModal());
  };

  const cancelFileUpload = async () => {
    try {
      await deleteIoRecord({
        analysisId: dataset.analysisId,
        ioRecordId: dataset.id,
      }).unwrap();
      toast({
        title: "Upload cancelled",
      });
      if (onModalClose) {
        onModalClose();
      }
    } catch (error) {
      toast({
        title: "Failed to cancel file upload",
        status: ToastType.Error,
      });
    }
    dispatch(closeModal());
  };

  const onDelete = async () => {
    await cancelFileUpload();
  };

  return (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      isCentered
      size="lg"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody className="flex flex-col justify-end !px-5 gap-3">
          <Flex className="justify-between items-center w-full">
            <Text className="text-xl font-semibold grow">Cancel Upload ?</Text>
            <ModalCloseButton className="!relative !top-0 !mr-0 !right-0" />
          </Flex>
          <Text className="">
            <span className="font-bold px-1">"{datasetName}"</span>
            will be removed permanently. Are you sure you would like to proceed
            ?
          </Text>
          <Flex className={"justify-between w-full mt-7"}>
            <Button
              mr={2}
              colorScheme="secondary"
              isDisabled={isCancelling}
              onClick={onClose}
              variant="ghost"
            >
              Close
            </Button>
            <Button
              colorScheme="customRed"
              isDisabled={isCancelling}
              isLoading={isCancelling}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onDelete}
            >
              Cancel
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancelUploadModal;
