import { Button, Flex, ModalCloseButton, ModalFooter } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const FatalErrorModal = () => {
  const dispatch = useDispatch();

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const onRefresh = () => {
    window.location.reload();
    onClose();
  };

  const header = modalProps?.header ?? "System encountered a fatal error!";
  const body =
    modalProps?.body ??
    "Try refreshing this page. Error report has been sent to our team.";
  const showRefresh = modalProps?.showRefresh ?? true;

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="!max-w-440 !pb-0 !gap-y-0.5">
        <Flex className="relative px-5">
          <Flex className="!font-normal !text-lg !text-red-600">{header}</Flex>
          <ModalCloseButton className="!mr-0 !-top-1" />
        </Flex>
        <ModalBody className="!px-5 !pb-4 !py-0 text-sm w-fit leading-[21px] text-gray-800">
          <Flex className="flex-col text-sm leading-[140%] text-gray-800">
            {body}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex className="flex-row !p-6 w-full justify-between">
            <Button
              colorScheme="dark"
              onClick={onClose}
              size="sm"
              variant="solid"
            >
              Okay
            </Button>
            {showRefresh && (
              <Button
                colorScheme="secondary"
                onClick={onRefresh}
                size="sm"
                variant="ghost"
              >
                Refresh Page
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
