import { Flex, ModalCloseButton, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@/design/components/modal";
import { ITransformationStep } from "@/features/data-transformation";
import { closeModal } from "@/slices/modal-slice.ts";
import { RootState } from "@/store";

export const ErrorModal = () => {
  const dispatch = useDispatch();

  const { modalProps } = useSelector(
    (state) => (state as RootState).rootReducer.modals
  );

  const erroredSteps = modalProps.issues as Partial<ITransformationStep>[];

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={true} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent className="!max-w-440 !pb-0 !gap-y-0.5">
        <Flex className="relative px-5">
          <Flex className="!font-normal !text-lg !text-gray-900">
            {erroredSteps.length} Issues found!
          </Flex>
          <ModalCloseButton className="!mr-0 !-top-1" />
        </Flex>
        <ModalBody className="!px-5 !pb-4 !py-0 text-sm w-fit leading-[21px] text-gray-800">
          <Flex className="flex-col text-sm leading-[160%] text-gray-800">
            <Text>The following steps have configuration issues:</Text>
            <Flex className="flex-col my-4 gap-y-1 text-red-600 font-bold">
              {erroredSteps.map((step, index) => (
                <Text>
                  {index + 1}. {step.name}
                </Text>
              ))}
            </Flex>
            <Text>Resolve the listed issues to execute the new steps.</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
