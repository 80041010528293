import { useContext, useEffect, useState } from "react";

import {
  EdaMetaDataContext,
  useGetRequestStatusQuery,
} from "@/features/data-transformation";
import { POLLING_STATUS } from "@/utils/enums.ts";

const POLLING_INTERVAL = 500;
export const useRequestStatus = <T>(props: { passedMetadata?: any }) => {
  const [requestId, setRequestId] = useState<string | undefined>();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const metaData = useContext(EdaMetaDataContext);

  const { data: runStatusData } = useGetRequestStatusQuery(
    {
      analysisId: props?.passedMetadata?.analysisId ?? metaData.analysisId!,
      edaId: props?.passedMetadata?.edaId ?? metaData.edaId!,
      requestId: requestId!,
    },
    {
      pollingInterval: POLLING_INTERVAL,
      skip: !requestId,
    }
  );
  useEffect(() => {
    console.log(runStatusData);
    const progressCheck =
      runStatusData?.response.data?.taskStatus != POLLING_STATUS.IN_PROGRESS;
    if (progressCheck) {
      setRequestId(undefined);
      setIsRequesting(false);
    }
  }, [runStatusData]);

  return {
    isRequesting,
    setRequestId,
    setIsRequesting,
    data: runStatusData?.response.data?.taskData as T | undefined,
  };
};
