import { Flex, Spinner, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import { ConnectorGrid } from "@/airbyte-component-docs/components/source/SelectConnector/ConnectorGrid";
import { AirbyteThemeProvider } from "@/airbyte-component-docs/hooks/theme/useAirbyteTheme";
import messages from "@/airbyte-component-docs/locales/en.json";
import { useSourceListRecordMutation } from "@/features/data-manager/api/data-query";

interface SelectConnectorProps {
  connectorType: "source" | "destination";
  onSelectConnectorDefinition: (definition: any, event?: any) => void;
}

export const SelectConnector: React.FC<SelectConnectorProps> = (props) => {
  return (
    <IntlProvider locale="en" messages={messages}>
      <AirbyteThemeProvider>
        <SelectConnectorSupportLevel {...props} />
      </AirbyteThemeProvider>
    </IntlProvider>
  );
};

const SelectConnectorSupportLevel: React.FC<SelectConnectorProps> = ({
  onSelectConnectorDefinition,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trigger] = useSourceListRecordMutation();
  const [sourceListData, setSourceListData] = useState<any>([]);

  useEffect(() => {
    const getRes = async () => {
      const response = await trigger({}).unwrap();
      setSourceListData(response?.response?.data?.results);
      setIsLoading(false);
    };
    void getRes();
  }, [trigger]);

  return (
    // add padding to the top and bottom of the grid
    <div style={{ overflowY: "auto", padding: "16px" }}>
      {isLoading ? (
        <Flex className="items-center justify-center" minH={"40vh"}>
          <Spinner size="sm" />
          <Box className="ml-2" pt="2xl">
            Loading...
          </Box>
        </Flex>
      ) : (
        <ConnectorGrid
          connectorDefinitions={sourceListData}
          onConnectorButtonClick={(definition: any, event: any) =>
            onSelectConnectorDefinition(definition, event)
          }
          searchResultsHiddenByFilters={0}
        />
      )}
    </div>
  );
};
