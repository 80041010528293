import React, { useEffect } from "react";

const OauthCallback: React.FC = () => {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const channel = new BroadcastChannel("oauth_channel");
    if (!code) {
      console.log("No code found in the URL");
      channel.postMessage({
        type: "error",
        message: "No code found in the URL",
      });

      return;
    } else if (code) {
      channel.postMessage({ type: "completed", query: { code } });

      setTimeout(() => {
        window.close();
      }, 1000);
    }
  }, []);

  return <div>Redirecting</div>;
};

export default OauthCallback;
