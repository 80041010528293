import { Box, Flex } from "@chakra-ui/react";

const DeleteAccountMessage = () => {
  return (
    <Flex className="flex-col gap-5">
      <Box>
        Deleting your account would remove all your data from our platform
        permanently. The authorship of your flows in the Flow Store will be
        transferred to your Workspace Admin.
      </Box>
      <Box>
        Once you confirm deletion, you will be logged out and your account will
        be deleted.
      </Box>
      {/* and your account will
        be deleted after 30 days. During this period, if you change your mind
        and wish to recover your account, you can simply log in again. */}
      <Flex className="text-red-600 flex-col gap-4">
        {/* <Box>
          After 30 days, your account will be permanently deleted and you will
          not be able to log in.
        </Box> */}
        <Box>
          Please note that <b>Account Deletion can not be undone</b>, even by
          our support team so make sure that you have read this warning
          carefully.
        </Box>
      </Flex>
    </Flex>
  );
};

export default DeleteAccountMessage;
